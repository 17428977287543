import React from 'react';

export const SmartsheetIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.8779 16.6081C16.5694 15.9972 16.2746 15.406 15.9264 14.844C15.485 14.131 15.006 13.4479 14.3515 12.9104C13.86 12.5068 13.3155 12.2511 12.6586 12.348C12.0919 12.4318 11.6348 12.73 11.2233 13.1085C11.0061 13.3084 10.8077 13.5275 10.6256 13.7919C10.7241 13.8051 10.8059 13.8128 10.8867 13.8275C11.4719 13.9342 11.922 14.275 12.3276 14.6821C13.0092 15.3669 13.5164 16.1783 13.9707 17.0235C14.9893 18.9184 15.7364 20.9249 16.3755 22.9747C16.3818 22.9946 16.3874 23.0145 16.4016 23.0616C18.9652 16.9297 21.8574 11.0099 25.842 5.69082L25.8879 5.70617C25.8879 5.84396 25.8879 5.98175 25.8879 6.11919C25.8879 10.5892 25.8928 15.0592 25.8848 19.5296C25.8823 20.8764 25.8618 22.224 25.7619 23.5684C25.7288 24.0139 25.6832 24.459 25.5252 24.8828C25.3407 25.3775 24.9877 25.5045 24.5396 25.2251C24.1595 24.9878 23.8643 24.6561 23.6161 24.2905C23.0852 23.5088 22.5742 22.7141 22.2194 21.8309C22.1968 21.7743 22.1912 21.6456 22.0673 21.766C21.259 22.5519 20.3201 23.1596 19.3544 23.7247C16.5788 25.3485 13.6114 26.4948 10.4982 27.2741C9.38109 27.5536 8.25561 27.7908 7.12213 27.9917C7.01248 28.0112 6.98428 28.0046 7.0076 27.8794C7.15903 27.0613 7.22831 26.2356 7.22831 25.4026C7.22622 18.34 7.22796 11.2775 7.22274 4.21488C7.22274 4.03802 7.26556 4 7.43857 4C13.5178 4.00488 19.5967 4.00419 25.6759 4.00419H25.8872C22.1578 7.69664 19.0087 11.7732 16.8789 16.6084L16.8779 16.6081Z"
			fill="#143158"
		/>
	</svg>
);
