import React from 'react';

export const SharePointSearchResultIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6321_165901)">
			<path
				d="M16.2797 17.7143C19.9787 17.7143 22.9774 14.6442 22.9774 10.8571C22.9774 7.07005 19.9787 4 16.2797 4C12.5807 4 9.58203 7.07005 9.58203 10.8571C9.58203 14.6442 12.5807 17.7143 16.2797 17.7143Z"
				fill="#036C70"
			/>
			<path
				d="M21.8602 23.4286C25.251 23.4286 27.9998 20.6144 27.9998 17.1429C27.9998 13.6714 25.251 10.8572 21.8602 10.8572C18.4695 10.8572 15.7207 13.6714 15.7207 17.1429C15.7207 20.6144 18.4695 23.4286 21.8602 23.4286Z"
				fill="#1A9BA1"
			/>
			<path
				d="M17.1163 27.9999C19.7364 27.9999 21.8604 25.8253 21.8604 23.1428C21.8604 20.4603 19.7364 18.2856 17.1163 18.2856C14.4961 18.2856 12.3721 20.4603 12.3721 23.1428C12.3721 25.8253 14.4961 27.9999 17.1163 27.9999Z"
				fill="#37C6D0"
			/>
			<path
				opacity="0.1"
				d="M17.395 10.1885V22.3828C17.3922 22.8065 17.1414 23.1872 16.7587 23.3485C16.6369 23.4013 16.5059 23.4285 16.3736 23.4285H12.3773C12.3718 23.3314 12.3718 23.24 12.3718 23.1428C12.3699 23.0474 12.3736 22.952 12.3829 22.8571C12.4851 21.0305 13.5842 19.4189 15.2239 18.6914V17.6285C11.5747 17.0365 9.08523 13.5279 9.66351 9.79181C9.66752 9.76593 9.67166 9.74007 9.67596 9.71425C9.70374 9.52153 9.74289 9.3307 9.79317 9.14282H16.3736C16.9368 9.14502 17.3929 9.61192 17.395 10.1885Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.8156 9.71436H9.67607C9.05586 13.4437 11.506 16.9816 15.1486 17.6166C15.2589 17.6358 15.3696 17.6522 15.4807 17.6658C13.7505 18.5058 12.4896 20.8915 12.3825 22.8572C12.3732 22.9521 12.3695 23.0475 12.3713 23.1429C12.3713 23.2401 12.3713 23.3315 12.3769 23.4286C12.387 23.6207 12.4112 23.8117 12.4495 24.0001H15.815C16.2289 23.9972 16.6007 23.7405 16.7583 23.3486C16.8098 23.2239 16.8364 23.0898 16.8364 22.9543V10.7601C16.8343 10.1837 16.3786 9.71687 15.8156 9.71436Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.8156 9.71436H9.67602C9.05594 13.444 11.5064 16.9821 15.1494 17.617C15.2239 17.63 15.2986 17.6417 15.3735 17.6521C13.6991 18.5526 12.4879 20.9327 12.383 22.8572H15.8156C16.3779 22.8529 16.8327 22.3872 16.837 21.8115V10.7601C16.8348 10.1835 16.3788 9.71655 15.8156 9.71436Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M15.2572 9.71436H9.67584C9.09032 13.2352 11.2452 16.6258 14.6265 17.5041C13.3462 19.0017 12.5609 20.8753 12.3828 22.8572H15.2572C15.8204 22.855 16.2765 22.3881 16.2786 21.8115V10.7601C16.2783 10.1827 15.8212 9.71468 15.2572 9.71436Z"
				fill="black"
			/>
			<path
				d="M5.02307 9.71436H15.256C15.821 9.71436 16.2791 10.1833 16.2791 10.7618V21.2384C16.2791 21.8168 15.821 22.2858 15.256 22.2858H5.02307C4.45804 22.2858 4 21.8168 4 21.2384V10.7618C4 10.1833 4.45804 9.71436 5.02307 9.71436Z"
				fill="#04868B"
			/>
			<path
				d="M8.56932 15.872C8.32956 15.7091 8.12929 15.4924 7.98383 15.2382C7.84287 14.9726 7.77262 14.6736 7.7801 14.3714C7.76755 13.9622 7.90243 13.5625 8.15907 13.2485C8.42876 12.9342 8.77736 12.7014 9.16707 12.5754C9.61123 12.4257 10.0764 12.352 10.544 12.3571C11.1589 12.3341 11.7729 12.4221 12.3579 12.6171V13.9314C12.1037 13.7737 11.8269 13.658 11.5375 13.5885C11.2235 13.5097 10.9013 13.4702 10.578 13.4708C10.2371 13.458 9.89841 13.5314 9.59181 13.6845C9.3551 13.7891 9.20161 14.0275 9.20112 14.2914C9.20015 14.4515 9.26023 14.6056 9.36856 14.7211C9.49651 14.8572 9.64787 14.968 9.81507 15.048C10.0011 15.1428 10.2802 15.2689 10.6523 15.4263C10.6933 15.4395 10.7332 15.4559 10.7717 15.4754C11.1379 15.6219 11.4912 15.8003 11.8277 16.0085C12.0825 16.1694 12.2964 16.3899 12.4517 16.652C12.611 16.949 12.6881 17.2848 12.675 17.6234C12.6932 18.0435 12.5676 18.457 12.32 18.7925C12.0732 19.101 11.7432 19.3284 11.3712 19.4463C10.9335 19.5867 10.4768 19.6546 10.0182 19.6474C9.60681 19.6493 9.19598 19.6149 8.79033 19.5445C8.44781 19.4872 8.11355 19.3866 7.79517 19.2451V17.8594C8.0995 18.0819 8.43951 18.2482 8.79982 18.3508C9.15892 18.4654 9.53221 18.5266 9.90829 18.5325C10.2564 18.5551 10.6038 18.4796 10.9129 18.3143C11.1295 18.1892 11.2612 17.9522 11.2556 17.6977C11.2571 17.5206 11.1887 17.3504 11.0659 17.2257C10.9132 17.0722 10.7364 16.9461 10.5429 16.8525C10.3197 16.7383 9.9909 16.5876 9.55667 16.4005C9.21122 16.2584 8.88047 16.0813 8.56932 15.872Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6321_165901">
				<rect width="24" height="24" fill="white" transform="translate(4 4)" />
			</clipPath>
		</defs>
	</svg>
);
