import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { FigmaIcon } from './icon';
import { FigmaSearchResultIcon } from './search-result-icon';

export const figmaConfig: ThirdPartyConfig = {
	displayName: 'Figma',
	id: 'figma',
	resourceType: 'figma-connector',
	icon: RovoLogoFromGlyph(FigmaIcon, 'Figma'),
	searchResultIcon: RovoLogoFromGlyph(FigmaSearchResultIcon, 'Figma'),
	integrationAri: 'ari:cloud:platform::integration/figma',
	typeFilterValues: [TypeFilterValueKey.Design],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
