import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { WebexFilterIcon } from './icon';
import { WebexIcon } from './search-result-icon';

export const webexConfig: ThirdPartyConfig = {
	displayName: 'Webex',
	id: 'webex',
	resourceType: 'smartlinks-connector-webex',
	icon: RovoLogoFromGlyph(WebexFilterIcon, 'Webex'),
	searchResultIcon: RovoLogoFromGlyph(WebexIcon, 'Webex'),
	integrationAri: 'ari:cloud:platform::integration/webex',
	typeFilterValues: [TypeFilterValueKey.Video],
	requiresOauth: true,
	isEnabled: () => true,
};
