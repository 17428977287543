import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import type { ThirdPartyConfig } from '../../schemas/3p-config';

import { DovetailFilterIcon } from './icon';
import { DovetailIcon } from './search-result-icon';

export const dovetailConfig: ThirdPartyConfig = {
	displayName: 'Dovetail',
	id: 'dovetail',
	resourceType: 'smartlinks-connector-dovetail',
	icon: RovoLogoFromGlyph(DovetailFilterIcon, 'Dovetail'),
	searchResultIcon: RovoLogoFromGlyph(DovetailIcon, 'Dovetail'),
	integrationAri: 'ari:cloud:platform::integration/dovetail',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
