import React from 'react';

export const ClickUpFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 16.0105L7.58713 13.8467C8.97035 15.7886 10.4304 16.704 12.0442 16.704C13.6579 16.704 15.0924 15.8163 16.3987 13.8744L19.0371 15.9828C17.1416 18.7569 14.785 20.2272 12.0442 20.2272C9.32896 20.2272 6.94675 18.7569 5 16.0105Z"
			fill="url(#paint0_linear_384_122)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.0186 7.32653L7.21498 11.2947L5 8.81138L12.0452 3L19.0371 8.81138L16.7954 11.2691L12.0186 7.32653Z"
			fill="url(#paint1_linear_384_122)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_384_122"
				x1="5"
				y1="17.04"
				x2="19.0485"
				y2="17.04"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#8930FD" />
				<stop offset="1" stopColor="#49CCF9" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_384_122"
				x1="4.99875"
				y1="7.1459"
				x2="19.0224"
				y2="7.1459"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF02F0" />
				<stop offset="1" stopColor="#FFC800" />
			</linearGradient>
		</defs>
	</svg>
);
