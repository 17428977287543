import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AmplitudeFilterIcon } from './icon';
import { AmplitudeIcon } from './search-result-icon';

export const amplitudeConfig: ThirdPartyConfig = {
	displayName: 'Amplitude',
	id: 'amplitude',
	resourceType: 'smartlinks-connector-amplitude',
	icon: RovoLogoFromGlyph(AmplitudeFilterIcon, 'Amplitude'),
	searchResultIcon: RovoLogoFromGlyph(AmplitudeIcon, 'Amplitude'),
	integrationAri: 'ari:cloud:platform::integration/amplitude',
	typeFilterValues: [TypeFilterValueKey.Dashboard],
	requiresOauth: true,
	isEnabled: () => false,
};
