import React from 'react';
export const AsanaFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.0853 12.8081C14.9233 12.8081 13.1707 14.5607 13.1707 16.7229C13.1707 18.8849 14.9233 20.6376 17.0853 20.6376C19.2474 20.6376 21 18.8849 21 16.7229C21 14.5607 19.2474 12.8081 17.0853 12.8081ZM6.91468 12.8084C4.75268 12.8084 3 14.5607 3 16.7229C3 18.8849 4.75268 20.6376 6.91468 20.6376C9.07677 20.6376 10.8295 18.8849 10.8295 16.7229C10.8295 14.5607 9.07677 12.8084 6.91468 12.8084ZM15.9146 7.91459C15.9146 10.0768 14.162 11.8296 12 11.8296C9.83793 11.8296 8.08533 10.0768 8.08533 7.91459C8.08533 5.75285 9.83793 4 12 4C14.162 4 15.9146 5.75285 15.9146 7.91459Z"
			fill="#F06A6A"
		/>
	</svg>
);
