import React from 'react';

export const MuralFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.6756 16.1765C12.6759 14.0137 12.6845 11.8507 12.6741 9.68782C12.6657 7.94507 13.8931 6.44062 15.5579 6.08258C15.8135 6.0276 16.0712 5.99206 16.3337 6.00441C16.3756 6.06677 16.3702 6.13801 16.3703 6.2076C16.3711 6.83737 16.3712 7.46729 16.37 8.09706C16.3699 8.17086 16.3806 8.24844 16.3279 8.3126C15.885 8.32766 15.5246 8.50601 15.2568 8.86058C15.0893 9.08245 14.9995 9.33415 14.993 9.61356C14.9916 9.67366 14.991 9.73376 14.991 9.79401C14.991 11.8641 14.991 13.9341 14.9907 16.0042C14.9907 16.0641 14.9865 16.1241 14.9844 16.1842C14.9394 16.2355 14.8772 16.2342 14.8178 16.2345C14.1628 16.237 13.5075 16.237 12.8525 16.2345C12.789 16.2342 12.7199 16.2404 12.6758 16.1765H12.6756Z"
			fill="#00843F"
		/>
		<path
			d="M19.9958 9.67021C19.997 9.72534 19.9991 9.78047 19.9991 9.8356C19.9992 12.661 19.9994 15.4863 19.9988 18.3118C19.9988 18.5254 20.0262 18.4965 19.8174 18.4968C19.1651 18.498 18.5127 18.4972 17.8602 18.4972C17.8251 18.4972 17.7899 18.4957 17.7548 18.4972C17.7016 18.4995 17.684 18.4712 17.6862 18.4228C17.6871 18.4028 17.6862 18.3827 17.6862 18.3625C17.6865 15.4719 17.6867 12.5814 17.687 9.6907C17.687 9.68573 17.6874 9.68061 17.6876 9.67564C17.7236 9.60454 17.7918 9.60349 17.8563 9.60319C18.5111 9.59972 19.1658 9.59987 19.8206 9.60273C19.8869 9.60303 19.9509 9.61237 19.9956 9.67006L19.9958 9.67021Z"
			fill="black"
		/>
		<path
			d="M6.31589 9.65857C6.31815 9.71355 6.32222 9.76853 6.32222 9.82351C6.32252 12.6569 6.32267 15.4904 6.32222 18.3238C6.32222 18.5292 6.34511 18.5008 6.1493 18.5011C5.48729 18.5021 4.82529 18.5021 4.16344 18.5003C4.11208 18.5003 4.05183 18.5252 4.01025 18.4693C4.02758 18.4152 4.01538 18.3599 4.01538 18.3054C4.01628 15.4762 4.01613 12.647 4.01613 9.81794C4.01613 9.617 4.02682 9.60224 4.22535 9.60133C4.85738 9.59862 5.4894 9.59953 6.12143 9.60118C6.19072 9.60133 6.26407 9.59591 6.31604 9.65857H6.31589Z"
			fill="black"
		/>
		<path
			d="M11.3293 18.4851C11.2817 18.5081 11.2307 18.4991 11.1811 18.4992C10.5239 18.5008 9.86659 18.5015 9.20941 18.5023C9.18425 18.5023 9.15925 18.503 9.13409 18.5021C9.06521 18.4996 9.02961 18.4616 9.0273 18.3882C9.02685 18.3732 9.0273 18.3581 9.0273 18.343C9.0273 15.4933 9.0273 12.6435 9.02745 9.7938C9.02745 9.74877 9.03076 9.70373 9.03257 9.65854C9.08439 9.59663 9.15714 9.60115 9.22643 9.60085C9.86117 9.5992 10.4959 9.5992 11.1306 9.60085C11.2001 9.601 11.2725 9.59724 11.3245 9.65869C11.3265 9.72376 11.3302 9.78883 11.3302 9.8539C11.3305 11.8991 11.3304 13.9443 11.3308 15.9894C11.3308 16.0543 11.3231 16.1198 11.3406 16.1838C11.3701 16.2445 11.37 16.3097 11.37 16.375C11.3701 17.0111 11.3707 17.6472 11.3694 18.2831C11.3694 18.3522 11.378 18.4247 11.3293 18.4848V18.4851Z"
			fill="#5887FF"
		/>
		<path
			d="M11.3247 9.65896C10.5607 9.65896 9.79675 9.65896 9.03263 9.65881C9.00822 9.44913 8.97343 9.24308 8.87477 9.05178C8.60861 8.53589 8.0406 8.24187 7.47124 8.32908C6.89584 8.41719 6.43884 8.87178 6.34515 9.45019C6.33401 9.51933 6.32557 9.58891 6.31593 9.65835C5.60302 9.65835 4.89011 9.65805 4.17719 9.6579C4.15716 9.6579 4.13682 9.65956 4.11694 9.65775C4.04795 9.65158 4.01226 9.67763 4.01753 9.75144C4.02039 9.79136 4.01783 9.83172 4.01783 9.87194C4.01783 12.6883 4.01783 15.5049 4.01753 18.3213C4.01753 18.371 4.01286 18.4207 4.01015 18.4698C3.99267 18.4308 4.00307 18.3903 4.00307 18.3511C4.00231 15.5011 4.00201 12.651 4.00277 9.801C4.00277 9.28525 4.06874 8.779 4.27389 8.30151C4.8172 7.03761 5.76675 6.26113 7.12404 6.04574C9.03022 5.74313 10.8088 6.97179 11.247 8.85401C11.3084 9.11806 11.3462 9.38617 11.3246 9.65896H11.3247Z"
			fill="#FF4B4B"
		/>
		<path
			d="M19.9958 9.67086C19.816 9.6689 19.6361 9.66543 19.4563 9.66513C18.9213 9.66438 18.3864 9.66468 17.8514 9.66513C17.7967 9.66513 17.7413 9.65941 17.6878 9.67658C17.6785 9.52701 17.6637 9.37954 17.6171 9.23479C17.4522 8.72221 16.9529 8.33104 16.4019 8.3214C16.377 8.32094 16.3523 8.31612 16.3276 8.31326C16.3303 8.28344 16.3353 8.25361 16.3353 8.22379C16.335 7.48406 16.3343 6.74449 16.3337 6.00492C18.3515 5.97976 20.0257 7.66918 19.9958 9.67101V9.67086Z"
			fill="#FC83FF"
		/>
		<path
			d="M11.3293 18.4856C11.3293 17.7733 11.3293 17.061 11.3298 16.3489C11.3298 16.294 11.3215 16.2385 11.3406 16.1847C11.7856 16.1821 12.2305 16.1796 12.6755 16.177C12.7252 16.1797 12.775 16.1847 12.8248 16.1847C13.5446 16.1851 14.2643 16.1848 14.9841 16.1847C14.9864 16.2345 14.9908 16.2845 14.9908 16.3344C14.9912 16.9957 14.9911 17.6571 14.9911 18.3183C14.9911 18.3534 14.9899 18.3885 14.9908 18.4234C14.992 18.4725 14.9704 18.4983 14.92 18.4977C14.8849 18.4974 14.8498 18.4985 14.8148 18.4985C13.7076 18.4985 12.6005 18.4985 11.4932 18.4983C11.4384 18.4983 11.3828 18.5054 11.3295 18.4855L11.3293 18.4856Z"
			fill="#FFAA00"
		/>
	</svg>
);
