import React from 'react';

export const PowerBIFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.4032 4H13.1619C12.7972 4 12.5016 4.29563 12.5016 4.66032V19.3397C12.5016 19.7044 12.7972 20 13.1619 20H17.4032C17.7679 20 18.0635 19.7044 18.0635 19.3397V4.66032C18.0635 4.29563 17.7679 4 17.4032 4Z"
			fill="url(#paint0_linear_384_141)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.7873 19.3397V20H9.88572C9.52102 20 9.2254 19.7044 9.2254 19.3397V8.64762C9.2254 8.28292 9.52102 7.9873 9.88572 7.9873H14.127C14.4917 7.9873 14.7873 8.28292 14.7873 8.64762V19.3397Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.7873 19.3397V20H9.88572C9.52102 20 9.2254 19.7044 9.2254 19.3397V8.64762C9.2254 8.28292 9.52102 7.9873 9.88572 7.9873H14.127C14.4917 7.9873 14.7873 8.28292 14.7873 8.64762V19.3397Z"
			fill="url(#paint1_linear_384_141)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.5619 19.3397V20H6.66032C6.29562 20 6 19.7044 6 19.3397V12.6603C6 12.2956 6.29562 12 6.66032 12H10.9016C11.2663 12 11.5619 12.2956 11.5619 12.6603V19.3397Z"
			fill="url(#paint2_linear_384_141)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_384_141"
				x1="15.2561"
				y1="3.97968"
				x2="15.2561"
				y2="19.9797"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#EBBB14" />
				<stop offset="1" stopColor="#B25400" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_384_141"
				x1="11.9799"
				y1="7.93778"
				x2="11.9799"
				y2="19.9505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9E583" />
				<stop offset="1" stopColor="#DE9800" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_384_141"
				x1="8.75454"
				y1="11.9797"
				x2="8.75454"
				y2="19.9797"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9E68B" />
				<stop offset="1" stopColor="#F3CD32" />
			</linearGradient>
		</defs>
	</svg>
);
