import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AdobeXDFilterIcon } from './icon';
import { AdobeXDIcon } from './search-result-icon';

export const adobeXDConfig: ThirdPartyConfig = {
	displayName: 'AdobeXD',
	id: 'adobexd',
	resourceType: 'smartlinks-connector-adobexd',
	icon: RovoLogoFromGlyph(AdobeXDFilterIcon, 'AdobeXD'),
	searchResultIcon: RovoLogoFromGlyph(AdobeXDIcon, 'AdobeXD'),
	integrationAri: 'ari:cloud:platform::integration/adobe',
	typeFilterValues: [TypeFilterValueKey.Design],
	requiresOauth: true,
	isEnabled: () => false,
};
