import React from 'react';

export const CanvaIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.9813 28C22.5984 28 27.9626 22.6274 27.9626 16C27.9626 9.37258 22.5984 4 15.9813 4C9.3642 4 4 9.37258 4 16C4 22.6274 9.3642 28 15.9813 28Z"
			fill="#7D2AE7"
		/>

		<path
			d="M15.9813 28C22.5984 28 27.9626 22.6274 27.9626 16C27.9626 9.37258 22.5984 4 15.9813 4C9.3642 4 4 9.37258 4 16C4 22.6274 9.3642 28 15.9813 28Z"
			fill="url(#paint0_radial_24_987)"
		/>

		<path
			d="M15.9813 28C22.5984 28 27.9626 22.6274 27.9626 16C27.9626 9.37258 22.5984 4 15.9813 4C9.3642 4 4 9.37258 4 16C4 22.6274 9.3642 28 15.9813 28Z"
			fill="url(#paint1_radial_24_987)"
		/>

		<path
			d="M15.9813 28C22.5984 28 27.9626 22.6274 27.9626 16C27.9626 9.37258 22.5984 4 15.9813 4C9.3642 4 4 9.37258 4 16C4 22.6274 9.3642 28 15.9813 28Z"
			fill="url(#paint2_radial_24_987)"
		/>

		<path
			d="M15.9813 28C22.5984 28 27.9626 22.6274 27.9626 16C27.9626 9.37258 22.5984 4 15.9813 4C9.3642 4 4 9.37258 4 16C4 22.6274 9.3642 28 15.9813 28Z"
			fill="url(#paint3_radial_24_987)"
		/>

		<path
			d="M21.1539 18.4617C21.055 18.4617 20.968 18.5453 20.8774 18.728C19.8549 20.8047 18.0887 22.2742 16.0449 22.2742C13.6817 22.2742 12.2182 20.1375 12.2182 17.1858C12.2182 12.1858 14.9997 9.29489 17.4429 9.29489C18.5845 9.29489 19.2817 10.0134 19.2817 11.1569C19.2817 12.514 18.5118 13.2326 18.5118 13.7112C18.5118 13.9261 18.6453 14.0562 18.9099 14.0562C19.9729 14.0562 21.2206 12.8327 21.2206 11.1044C21.2206 9.42857 19.7643 8.19678 17.3213 8.19678C13.2836 8.19678 9.69531 11.9459 9.69531 17.1333C9.69531 21.1486 11.9846 23.802 15.517 23.802C19.2661 23.802 21.434 20.0659 21.434 18.8533C21.434 18.5847 21.2969 18.4617 21.1539 18.4617Z"
			fill="white"
		/>

		<defs>
			<radialGradient
				id="paint0_radial_24_987"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(8.62867 25.2717) rotate(-49.4602) scale(18.5497 18.5453)"
			>
				<stop stopColor="#6420FF" />
				<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
			</radialGradient>

			<radialGradient
				id="paint1_radial_24_987"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(10.3437 6.72837) rotate(54.7452) scale(20.9211 20.9103)"
			>
				<stop stopColor="#00C4CC" />
				<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
			</radialGradient>

			<radialGradient
				id="paint2_radial_24_987"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(8.62856 25.2716) rotate(-45.2401) scale(18.3231 8.42692)"
			>
				<stop stopColor="#6420FF" />
				<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
			</radialGradient>

			<radialGradient
				id="paint3_radial_24_987"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(13.7994 7.23368) rotate(66.5525) scale(18.8904 31.6119)"
			>
				<stop stopColor="#00C4CC" stopOpacity="0.725916" />
				<stop offset="0.0001" stopColor="#00C4CC" />
				<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
			</radialGradient>
		</defs>
	</svg>
);
