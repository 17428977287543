import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { LucidFilterIcon } from './icon';
import { LucidIcon } from './search-result-icon';

export const lucidConfig: ThirdPartyConfig = {
	displayName: 'Lucid',
	id: 'lucid',
	resourceType: 'lucid-connector',
	icon: RovoLogoFromGlyph(LucidFilterIcon, 'Lucid'),
	searchResultIcon: RovoLogoFromGlyph(LucidIcon, 'Lucid'),
	integrationAri: 'ari:cloud:platform::integration/lucid',
	typeFilterValues: [TypeFilterValueKey.Design],
	filters: ['lastUpdated'],
	requiresOauth: false,
	isEnabled: () => true,
};
