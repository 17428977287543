import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import GoogleCalendarIcon from './icon';

export const googleCalendarConfig: ThirdPartyConfig = {
	displayName: 'Google Calendar',
	id: 'google-calendar',
	resourceType: 'google-calendar-connector',
	icon: RovoLogoFromGlyph(GoogleCalendarIcon, 'Google Calendar'),
	integrationAri: 'ari:cloud:platform::integration/google',
	requiresOauth: true,
	typeFilterValues: [TypeFilterValueKey.Calendar],
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_google_calendar'),
};
