import React from 'react';
export const GitlabFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.7331 11.0987L19.7115 11.0412L17.5339 5.36091C17.4898 5.24937 17.4114 5.15476 17.31 5.09076C17.2343 5.04232 17.1483 5.01228 17.059 5.00304C16.9696 4.99381 16.8793 5.00565 16.7953 5.0376C16.7113 5.06956 16.636 5.12074 16.5754 5.18705C16.5147 5.25337 16.4705 5.33296 16.4461 5.41946L14.9763 9.91949H9.02381L7.55392 5.41946C7.5294 5.33309 7.48504 5.25364 7.42438 5.18746C7.36371 5.12127 7.28842 5.07018 7.20451 5.03825C7.1206 5.00632 7.03039 4.99444 6.94107 5.00356C6.85175 5.01268 6.7658 5.04254 6.69007 5.09076C6.58865 5.15476 6.51023 5.24937 6.46615 5.36091L4.28957 11.0422L4.26697 11.0987C3.95361 11.9177 3.91501 12.8163 4.15698 13.6591C4.39895 14.502 4.90838 15.2433 5.60846 15.7713L5.61668 15.7775L5.63517 15.7918L8.9478 18.2745L10.5913 19.5164L11.5897 20.2713C11.7068 20.3598 11.8496 20.4077 11.9964 20.4077C12.1432 20.4077 12.2861 20.3598 12.4032 20.2713L13.4016 19.5164L15.0451 18.2745L18.3813 15.7775L18.3906 15.7703C19.0906 15.2424 19.6001 14.5014 19.8423 13.6588C20.0844 12.8161 20.0461 11.9176 19.7331 11.0987Z"
			fill="#E24329"
		/>
		<path
			d="M19.7331 11.0987L19.7115 11.0411C18.6505 11.2589 17.6508 11.7085 16.784 12.358L12.0026 15.9736L15.0471 18.2745L18.3833 15.7774L18.3926 15.7702C19.0923 15.2421 19.6014 14.501 19.8431 13.6583C20.0849 12.8157 20.0463 11.9174 19.7331 11.0987Z"
			fill="#FC6D26"
		/>
		<path
			d="M8.94781 18.2745L10.5913 19.5163L11.5897 20.2713C11.7068 20.3598 11.8496 20.4077 11.9965 20.4077C12.1433 20.4077 12.2861 20.3598 12.4032 20.2713L13.4016 19.5163L15.0451 18.2745L12.0006 15.9736L8.94781 18.2745Z"
			fill="#FCA326"
		/>
		<path
			d="M7.21598 12.358C6.34952 11.7089 5.35016 11.2596 4.28957 11.0422L4.26697 11.0987C3.95361 11.9177 3.91501 12.8164 4.15698 13.6592C4.39895 14.502 4.90838 15.2433 5.60846 15.7713L5.61668 15.7775L5.63517 15.7919L8.9478 18.2745L11.9944 15.9737L7.21598 12.358Z"
			fill="#FC6D26"
		/>
	</svg>
);
