import React from 'react';

export const StripeFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M5 5H19V19H5V5Z" fill="#635BFF" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4541 10.4422C11.4541 10.1132 11.7236 9.98366 12.1716 9.98366C12.8156 9.98366 13.6276 10.1797 14.2716 10.5262V8.53816C13.5681 8.25816 12.8751 8.14966 12.1751 8.14966C10.4566 8.14966 9.31555 9.04566 9.31555 10.5437C9.31555 12.8782 12.5321 12.5072 12.5321 13.5152C12.5321 13.9037 12.1926 14.0297 11.7201 14.0297C11.0166 14.0297 10.1206 13.7427 9.41005 13.3542V15.3667C10.1976 15.7062 10.9921 15.8497 11.7201 15.8497C13.4806 15.8497 14.6916 14.9782 14.6916 13.4627C14.6776 10.9427 11.4541 11.3907 11.4541 10.4422Z"
			fill="white"
		/>
	</svg>
);
