import React from 'react';
export const GitlabIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M27.5996 13.148L27.5673 13.0617L24.3009 4.54136C24.2348 4.37406 24.1171 4.23214 23.965 4.13614C23.8515 4.06348 23.7225 4.01841 23.5885 4.00457C23.4544 3.99072 23.3189 4.00847 23.193 4.0564C23.067 4.10434 22.954 4.18111 22.8631 4.28058C22.7721 4.38005 22.7057 4.49944 22.6692 4.62919L20.4644 11.3792H11.5357L9.33089 4.62919C9.29411 4.49963 9.22757 4.38047 9.13657 4.28119C9.04557 4.18191 8.93264 4.10527 8.80677 4.05738C8.6809 4.00949 8.54558 3.99167 8.41161 4.00534C8.27763 4.01902 8.14871 4.06381 8.03511 4.13614C7.88298 4.23214 7.76534 4.37406 7.69922 4.54136L4.43436 13.0633L4.40046 13.148C3.93042 14.3765 3.87251 15.7245 4.23547 16.9887C4.59843 18.2529 5.36258 19.3649 6.41269 20.1569L6.42502 20.1662L6.45275 20.1877L11.4217 23.9118L13.8869 25.7745L15.3845 26.907C15.5602 27.0397 15.7744 27.1115 15.9947 27.1115C16.2149 27.1115 16.4291 27.0397 16.6048 26.907L18.1024 25.7745L20.5676 23.9118L25.572 20.1662L25.5859 20.1554C26.6359 19.3637 27.4002 18.2521 27.7634 16.9881C28.1266 15.7242 28.0692 14.3764 27.5996 13.148Z"
			fill="#E24329"
		/>
		<path
			d="M27.5996 13.148L27.5673 13.0618C25.9758 13.3883 24.4763 14.0629 23.1761 15.037L16.0039 20.4605L20.5707 23.9118L25.5751 20.1662L25.589 20.1554C26.6385 19.3633 27.4021 18.2515 27.7648 16.9876C28.1274 15.7237 28.0695 14.3761 27.5996 13.148Z"
			fill="#FC6D26"
		/>
		<path
			d="M11.4218 23.9117L13.887 25.7745L15.3846 26.907C15.5603 27.0397 15.7745 27.1115 15.9947 27.1115C16.2149 27.1115 16.4291 27.0397 16.6049 26.907L18.1025 25.7745L20.5677 23.9117L16.0009 20.4604L11.4218 23.9117Z"
			fill="#FCA326"
		/>
		<path
			d="M8.82398 15.0371C7.52428 14.0634 6.02525 13.3894 4.43436 13.0634L4.40046 13.1481C3.93042 14.3766 3.87251 15.7245 4.23547 16.9888C4.59843 18.253 5.36258 19.365 6.41269 20.157L6.42502 20.1662L6.45275 20.1878L11.4217 23.9118L15.9916 20.4605L8.82398 15.0371Z"
			fill="#FC6D26"
		/>
	</svg>
);
