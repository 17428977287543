import React from 'react';

export const GoogleDriveIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_271_181643)">
			<g clipPath="url(#clip1_271_181643)">
				<rect x="2" y="2" width="20" height="20" rx="0.75" fill="white" fillOpacity="0.01" />
				<g clipPath="url(#clip2_271_181643)">
					<path
						d="M3.51203 18.5459L4.39404 20.1089C4.57732 20.438 4.84078 20.6965 5.15006 20.8845L8.30011 15.2905H2C2 15.6548 2.09164 16.0192 2.27491 16.3482L3.51203 18.5459Z"
						fill="#0066DA"
					/>
					<path
						d="M12 8.70925L8.84994 3.11523C8.54066 3.30327 8.27721 3.56182 8.09393 3.89088L2.27491 14.2328C2.09501 14.5547 2.00024 14.9193 2 15.2904H8.30011L12 8.70925Z"
						fill="#00AC47"
					/>
					<path
						d="M18.85 20.8845C19.1593 20.6965 19.4227 20.438 19.606 20.1089L19.9725 19.4625L21.7251 16.3482C21.9084 16.0192 22 15.6548 22 15.2905H15.6995L17.0401 17.9935L18.85 20.8845Z"
						fill="#EA4335"
					/>
					<path
						d="M12.0002 8.70932L15.1502 3.1153C14.8409 2.92727 14.4858 2.83325 14.1193 2.83325H9.88103C9.51447 2.83325 9.15938 2.93902 8.8501 3.1153L12.0002 8.70932Z"
						fill="#00832D"
					/>
					<path
						d="M15.6997 15.2905H8.29996L5.1499 20.8845C5.45918 21.0726 5.81428 21.1666 6.18083 21.1666H17.8189C18.1854 21.1666 18.5405 21.0608 18.8498 20.8845L15.6997 15.2905Z"
						fill="#2684FC"
					/>
					<path
						d="M18.8156 9.06182L15.9061 3.89088C15.7228 3.56182 15.4593 3.30327 15.1501 3.11523L12 8.70925L15.6999 15.2904H21.9885C21.9885 14.9261 21.8969 14.5618 21.7136 14.2328L18.8156 9.06182Z"
						fill="#FFBA00"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_271_181643">
				<rect width="24" height="24" fill="white" />
			</clipPath>
			<clipPath id="clip1_271_181643">
				<rect x="2" y="2" width="20" height="20" rx="0.75" fill="white" />
			</clipPath>
			<clipPath id="clip2_271_181643">
				<rect width="20" height="18.3333" fill="white" transform="translate(2 2.83325)" />
			</clipPath>
		</defs>
	</svg>
);
