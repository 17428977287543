import React from 'react';

export const TodoistFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.4458 4H6.59025C5.17958 4 4.03601 5.14358 4.03601 6.55425V17.4098C4.03601 18.8205 5.17958 19.9641 6.59025 19.9641H17.4458C18.8564 19.9641 20 18.8205 20 17.4098V6.55425C20 5.14358 18.8564 4 17.4458 4Z"
			fill="#DE483A"
		/>
		<path
			d="M7.41031 11.4797L10.5901 9.64985L10.5941 9.64735L13.8707 7.7616C14.0089 7.68178 14.0154 7.43733 13.8608 7.34853L13.7525 7.28667C13.5944 7.19687 13.3898 7.08013 13.3025 7.02924C13.226 6.9876 13.1401 6.96627 13.053 6.96732C12.966 6.96837 12.8806 6.99175 12.8051 7.03523C12.7278 7.08013 7.56645 10.0475 7.39384 10.1457C7.29213 10.2039 7.17701 10.2345 7.05985 10.2345C6.94269 10.2345 6.82756 10.2039 6.72585 10.1457L4 8.5618V9.90827L4.02794 9.92423C4.7084 10.3208 6.31877 11.2587 6.71288 11.4807C6.95184 11.6154 7.18033 11.6124 7.41031 11.4797Z"
			fill="white"
		/>
		<path
			d="M7.41031 14.1697L10.5861 12.3418L10.5981 12.3348L13.8707 10.4515C14.0089 10.3717 14.0154 10.1268 13.8608 10.0385L13.7525 9.97661C13.5949 9.88681 13.3898 9.77007 13.3025 9.71919C13.226 9.67754 13.1401 9.65622 13.053 9.65726C12.966 9.65831 12.8806 9.68169 12.8051 9.72517C12.7278 9.76957 7.56645 12.7374 7.39384 12.8352C7.29213 12.8933 7.17701 12.9239 7.05985 12.9239C6.94269 12.9239 6.82756 12.8933 6.72585 12.8352C6.56322 12.7414 4 11.2517 4 11.2517V12.5977L4.02794 12.6142C4.7089 13.0108 6.31877 13.9482 6.71288 14.1707C6.95184 14.3054 7.18033 14.3024 7.41031 14.1697Z"
			fill="white"
		/>
		<path
			d="M10.5906 15.0292L7.41031 16.8596C7.18033 16.9923 6.95134 16.9953 6.71288 16.8606C6.31877 16.6381 4.7089 15.7007 4.02794 15.3041L4 15.2877V13.9417L6.72585 15.5251C6.93189 15.6444 7.18731 15.6429 7.39384 15.5251C7.56645 15.4273 12.7278 12.4595 12.8051 12.4146C12.8806 12.3711 12.966 12.3478 13.053 12.3467C13.1401 12.3457 13.226 12.367 13.3025 12.4086C13.4882 12.516 13.6745 12.6225 13.8613 12.7279C14.0159 12.8167 14.0089 13.0617 13.8707 13.1415L10.5906 15.0292Z"
			fill="white"
		/>
	</svg>
);
