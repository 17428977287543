import React from 'react';

export const LucidIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.01609 18.4094C8.01609 15.3886 8.02413 12.3686 8.00644 9.3478C8.00402 8.92026 8.13108 8.68284 8.51387 8.46626C11.0068 7.05533 13.4829 5.61472 15.9646 4.18373C16.4591 3.89817 16.4841 3.91101 16.4841 4.46928C16.4841 10.4299 16.4841 16.3904 16.4841 22.3518C16.4841 22.4721 16.4921 22.5933 16.4832 22.7128C16.4615 23.016 16.5685 23.0665 16.8266 22.9149C17.6003 22.4617 18.3795 22.0173 19.1563 21.5697C20.8958 20.5671 22.6352 19.5636 24.3754 18.5618C24.788 18.3244 24.8346 18.3508 24.8346 18.8369C24.8362 21.6299 24.8362 24.4237 24.8346 27.2167C24.8346 27.7349 24.8041 27.7645 24.2902 27.7653C21.8246 27.7694 19.359 27.7637 16.8942 27.7814C16.4945 27.7846 16.3546 27.6707 16.3626 27.26C16.3867 26.0841 16.3723 24.9082 16.3707 23.7315C16.3707 23.312 16.3626 23.3039 15.9935 23.5165C13.4981 24.9507 11.0052 26.3897 8.50904 27.8231C8.37716 27.8985 8.24286 28.0718 8.08203 27.9675C7.95014 27.8825 8.01689 27.694 8.01689 27.552C8.01448 24.5047 8.01448 21.4574 8.01448 18.4102L8.01609 18.4094Z"
			fill="#222C35"
		/>
	</svg>
);
