import React from 'react';

export const GmailSearchResultIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1061_12827)">
			<path
				d="M5.63636 25H9.45455V15.7273L4 11.6364V23.3636C4 24.2691 4.73364 25 5.63636 25Z"
				fill="#4285F4"
			/>
			<path
				d="M22.5454 25H26.3636C27.269 25 28 24.2664 28 23.3636V11.6364L22.5454 15.7273"
				fill="#34A853"
			/>
			<path
				d="M22.5454 8.63637V15.7273L28 11.6364V9.45455C28 7.43091 25.69 6.27728 24.0727 7.49091"
				fill="#FBBC04"
			/>
			<path
				d="M9.45459 15.7273V8.63637L16 13.5455L22.5455 8.63637V15.7273L16 20.6364"
				fill="#EA4335"
			/>
			<path
				d="M4 9.45455V11.6364L9.45455 15.7273V8.63637L7.92727 7.49091C6.30727 6.27728 4 7.43091 4 9.45455Z"
				fill="#C5221F"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1061_12827">
				<rect width="24" height="18" fill="white" transform="translate(4 7)" />
			</clipPath>
		</defs>
	</svg>
);
