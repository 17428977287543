import React from 'react';
export const ServiceNowFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.0473 5.00214C10.4382 4.99878 8.86528 5.47886 7.53234 6.38014C6.19941 7.28142 5.16796 8.56233 4.57171 10.0568C3.97546 11.5513 3.84191 13.1905 4.18837 14.7618C4.53483 16.333 5.34532 17.764 6.51478 18.8692C6.78908 19.1304 7.14782 19.2848 7.52608 19.3044C7.90434 19.3241 8.27713 19.2077 8.57701 18.9763C9.56641 18.24 10.7668 17.8423 12.0001 17.8423C13.2334 17.8423 14.4339 18.24 15.4233 18.9763C15.726 19.2082 16.1021 19.3236 16.4828 19.3013C16.8635 19.2791 17.2236 19.1208 17.4973 18.8553C18.6576 17.7542 19.4626 16.3317 19.8092 14.7702C20.1558 13.2086 20.0282 11.5791 19.4426 10.0905C18.8571 8.60198 17.8404 7.3222 16.5228 6.41525C15.2052 5.50829 13.6467 5.01549 12.0473 5M12.0001 17.0382C11.4713 17.0525 10.9451 16.9589 10.4536 16.7631C9.96216 16.5674 9.51573 16.2735 9.1416 15.8994C8.76746 15.5254 8.47347 15.0791 8.27754 14.5876C8.08161 14.0962 7.98787 13.57 8.00202 13.0412C8.00202 11.9808 8.42326 10.9639 9.17305 10.2141C9.92284 9.46432 10.9398 9.04309 12.0001 9.04309C13.0605 9.04309 14.0774 9.46432 14.8272 10.2141C15.577 10.9639 15.9983 11.9808 15.9983 13.0412C16.0124 13.57 15.9187 14.0962 15.7227 14.5876C15.5268 15.0791 15.2328 15.5254 14.8587 15.8994C14.4846 16.2735 14.0381 16.5674 13.5467 16.7631C13.0552 16.9589 12.529 17.0525 12.0001 17.0382Z"
			fill="#62D84E"
		/>
	</svg>
);
