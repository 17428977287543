import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { ServiceNowFilterIcon } from './icon';
import { ServiceNowIcon } from './search-result-icon';

export const serviceNowConfig: ThirdPartyConfig = {
	displayName: 'ServiceNow',
	id: 'servicenow',
	icon: RovoLogoFromGlyph(ServiceNowFilterIcon, 'ServiceNow'),
	resourceType: 'servicenow-connector',
	searchResultIcon: RovoLogoFromGlyph(ServiceNowIcon, 'ServiceNow'),
	integrationAri: 'ari:cloud:platform::integration/servicenow', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future,
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => true,
};
