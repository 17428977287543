import React from 'react';

export const PipedriveIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.6003 13.0616C13.6003 15.3341 14.7531 17.7855 17.2899 17.7855C19.1712 17.7855 21.0734 16.3167 21.0734 13.0283C21.0734 10.1452 19.5781 8.2097 17.3525 8.2097C15.539 8.2097 13.6003 9.48365 13.6003 13.0616ZM18.2462 4C22.7948 4 25.8533 7.60255 25.8533 12.9651C25.8533 18.2435 22.6314 21.9285 18.0254 21.9285C15.8294 21.9285 14.4227 20.9879 13.6872 20.3071C13.6925 20.4685 13.6959 20.6493 13.6959 20.8423V28H8.98391V8.92915C8.98391 8.65189 8.89524 8.56416 8.62051 8.56416H7V4.38781H10.9539C12.7744 4.38781 13.2404 5.31432 13.329 6.02851C14.068 5.20027 15.5999 4 18.2462 4Z"
			fill="#017737"
		/>
	</svg>
);
