import React from 'react';
export const MondayIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.98551 23.3893C6.45497 23.3905 5.93363 23.2508 5.47475 22.9845C5.01587 22.7182 4.6359 22.3349 4.37366 21.8737C4.11543 21.4158 3.98664 20.8962 4.0011 20.3707C4.01555 19.8451 4.1727 19.3334 4.45571 18.8904L9.83438 10.444C10.1097 9.9901 10.5005 9.61736 10.967 9.3639C11.4335 9.11044 11.9589 8.98533 12.4895 9.00137C13.0198 9.01408 13.5371 9.16743 13.9886 9.44575C14.4402 9.72407 14.8096 10.1173 15.0592 10.5853C15.5606 11.5334 15.4979 12.6741 14.8974 13.5641L9.52215 22.0105C9.24956 22.4352 8.87416 22.7843 8.43073 23.0253C7.9873 23.2664 7.49021 23.3916 6.98551 23.3893Z"
			fill="#F62B54"
		/>
		<path
			d="M16.2113 23.3891C15.1264 23.3891 14.1282 22.809 13.604 21.8769C13.3463 21.4202 13.2178 20.902 13.2323 20.3778C13.2468 19.8537 13.4036 19.3433 13.686 18.9015L19.0545 10.4746C19.3257 10.0142 19.7151 9.63486 20.1824 9.37577C20.6497 9.11669 21.1778 8.98733 21.7119 9.00112C22.8059 9.02505 23.7973 9.63699 24.2987 10.5942C24.7966 11.5514 24.7237 12.699 24.1049 13.5867L18.7377 22.0136C18.4659 22.4366 18.092 22.7843 17.6505 23.0247C17.209 23.2651 16.714 23.3904 16.2113 23.3891Z"
			fill="#FFCC00"
		/>
		<path
			d="M25.2309 23.4598C26.7602 23.4598 28 22.246 28 20.7488C28 19.2515 26.7602 18.0378 25.2309 18.0378C23.7016 18.0378 22.4618 19.2515 22.4618 20.7488C22.4618 22.246 23.7016 23.4598 25.2309 23.4598Z"
			fill="#00CA72"
		/>
	</svg>
);
