import React from 'react';

export const DovetailFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.88 5H6.12C5.50144 5 5 5.50144 5 6.12V17.88C5 18.4986 5.50144 19 6.12 19H17.88C18.4986 19 19 18.4986 19 17.88V6.12C19 5.50144 18.4986 5 17.88 5Z"
			fill="#0C0020"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.3685 13.0781C8.34909 13.0893 8.34909 13.1174 8.3685 13.1286L10.1967 14.1844C10.2147 14.1948 10.2258 14.214 10.2258 14.2348V16.3458C10.2258 16.3682 10.25 16.3822 10.2695 16.371L12.0976 15.3152C12.1156 15.3048 12.1267 15.2856 12.1267 15.2648V13.137C12.1267 13.1162 12.1156 13.0969 12.0976 13.0865L10.2258 12.0061L8.3685 13.0781Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.3685 8.68802C8.34909 8.69924 8.34909 8.72726 8.3685 8.73846L10.1967 9.79425C10.2147 9.80466 10.2258 9.82388 10.2258 9.84469V12.0061L12.1267 10.9083V8.74688C12.1267 8.72607 12.1156 8.70684 12.0976 8.69642L10.2549 7.63281C10.2369 7.6224 10.2147 7.6224 10.1967 7.63281L8.3685 8.68802Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1267 10.9083L13.9985 11.9893C14.0166 11.9997 14.0277 12.019 14.0277 12.0398V14.1508C14.0277 14.1732 14.0519 14.1872 14.0713 14.176L15.8995 13.1202C15.9175 13.1098 15.9286 13.0906 15.9286 13.0698V10.9419C15.9286 10.9211 15.9175 10.9019 15.8995 10.8915L14.0568 9.82786C14.0388 9.81747 14.0166 9.81747 13.9985 9.82786L12.1267 10.9083Z"
			fill="white"
		/>
	</svg>
);
