import React from 'react';

export const PipedriveFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.4002 10.0411C10.4002 11.556 11.1687 13.1903 12.8599 13.1903C14.1142 13.1903 15.3823 12.2112 15.3823 10.0189C15.3823 8.0968 14.3854 6.80647 12.9017 6.80647C11.6927 6.80647 10.4002 7.65577 10.4002 10.0411ZM13.4975 4C16.5299 4 18.5688 6.4017 18.5688 9.97675C18.5688 13.4957 16.4209 15.9523 13.3503 15.9523C11.8862 15.9523 10.9485 15.3253 10.4582 14.8714C10.4616 14.979 10.4639 15.0995 10.4639 15.2282V20H7.32261V7.2861C7.32261 7.10126 7.26349 7.04278 7.08034 7.04278H6V4.25854H8.63594C9.84959 4.25854 10.1602 4.87621 10.2194 5.35234C10.712 4.80018 11.7332 4 13.4975 4Z"
			fill="#017737"
		/>
	</svg>
);
