import React from 'react';
export const AdobeSignFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.82667 4H17.1733C18.72 4 20 5.28 20 6.82667V16.7467C20 18.32 18.72 19.5733 17.1733 19.5733H6.82667C5.28 19.6 4 18.32 4 16.7733V6.82667C4 5.28 5.28 4 6.82667 4Z"
			fill="#584CCC"
		/>
		<path
			d="M16.8533 13.0401C16.5333 12.6934 15.92 12.5334 15.04 12.5334C14.56 12.5334 14.0533 12.5868 13.5733 12.6668C13.2533 12.3734 12.9867 12.0268 12.72 11.6801C12.5333 11.4134 12.3467 11.1468 12.1867 10.8534C12.4533 10.1068 12.6133 9.30676 12.6667 8.50676C12.6667 7.78676 12.3733 7.01343 11.5733 7.01343C11.3067 7.01343 11.04 7.17343 10.9067 7.41343C10.56 8.00009 10.6933 9.20009 11.2533 10.4534C11.0667 11.0134 10.8533 11.5734 10.6133 12.1868C10.4 12.7201 10.16 13.2268 9.86668 13.7068C9.04001 14.0268 7.30668 14.8268 7.14668 15.7068C7.09334 15.9734 7.17334 16.2401 7.38668 16.4001C7.57334 16.5601 7.84001 16.6668 8.10668 16.6401C9.14668 16.6401 10.1867 15.2001 10.9067 13.9468C11.3067 13.8134 11.7067 13.6801 12.1333 13.5734C12.5867 13.4668 13.0133 13.3601 13.4133 13.2801C14.5333 14.2401 15.52 14.4001 16 14.4001C16.6667 14.4001 16.9333 14.1068 17.0133 13.8668C17.12 13.5734 17.0667 13.2534 16.8533 13.0401ZM16.1333 13.5468C16.1067 13.7334 15.8933 13.8668 15.6267 13.8668C15.5467 13.8668 15.4667 13.8668 15.3867 13.8401C14.8533 13.7068 14.3467 13.4401 13.92 13.0934C14.2667 13.0401 14.64 13.0134 14.9867 13.0134C15.2267 13.0134 15.4667 13.0401 15.7067 13.0668C15.9467 13.1201 16.2133 13.2268 16.1333 13.5468ZM11.36 7.65343C11.4133 7.57343 11.4933 7.52009 11.6 7.49343C11.8667 7.49343 11.92 7.78676 11.92 8.05343C11.8933 8.66676 11.7867 9.25343 11.6 9.84009C11.1467 8.69343 11.2 7.92009 11.36 7.65343ZM12.8533 12.7734C12.56 12.8268 12.2667 12.9068 11.9733 12.9868C11.76 13.0401 11.5467 13.1201 11.3067 13.1734C11.4133 12.9334 11.52 12.6934 11.6267 12.4801C11.76 12.1868 11.8667 11.8934 11.9733 11.6001C12.08 11.7601 12.16 11.8934 12.2667 12.0268C12.4533 12.2934 12.6667 12.5334 12.8533 12.7734ZM9.62668 14.3201C8.96001 15.3868 8.29334 16.0801 7.92001 16.0801C7.86668 16.0801 7.78668 16.0534 7.76001 16.0268C7.68001 15.9734 7.65334 15.8668 7.68001 15.7868C7.73334 15.3868 8.50668 14.8268 9.62668 14.3201Z"
			fill="white"
		/>
	</svg>
);
