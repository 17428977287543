import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl-next';

import GlobeIcon from '@atlaskit/icon/core/globe';
import {
	AtlassianIcon,
	BitbucketIcon,
	CompassIcon,
	ConfluenceIcon,
	FocusIcon,
	JiraIcon,
	JiraProductDiscoveryIcon,
	JiraServiceManagementIcon,
	TrelloIcon,
} from '@atlaskit/logo';
import { fg } from '@atlaskit/platform-feature-flags';

// This is the only place we want to import this
import {
	DO_NOT_IMPORT_ProductKeys3P,
	DO_NOT_IMPORT_ProductsOrder3P,
	ThirdPartyConfigs,
} from './3p-product-configs';
import { AdobeSignFilterIcon } from './3p-product-configs/adobe-sign/icon';
import { AdobeSignIcon } from './3p-product-configs/adobe-sign/search-result-icon';
import { AdobeXDFilterIcon } from './3p-product-configs/adobe-xd/icon';
import { AdobeXDIcon } from './3p-product-configs/adobe-xd/search-result-icon';
import { AhaFilterIcon } from './3p-product-configs/aha/icon';
import { AhaIcon } from './3p-product-configs/aha/search-result-icon';
import { AirtableFilterIcon } from './3p-product-configs/airtable/icon';
import { AirtableIcon } from './3p-product-configs/airtable/search-result-icon';
import { AmplitudeFilterIcon } from './3p-product-configs/amplitude/icon';
import { AmplitudeIcon } from './3p-product-configs/amplitude/search-result-icon';
import { AsanaFilterIcon } from './3p-product-configs/asana/icon';
import { AsanaIcon } from './3p-product-configs/asana/search-result-icon';
import { WebDomainSearchResultIcon } from './3p-product-configs/atlassian-web-crawler/search-result-icon';
import { AzureDevopsFilterIcon } from './3p-product-configs/azure-devops/icon';
import { AzureDevopsIcon } from './3p-product-configs/azure-devops/search-result-icon';
import { BoxIcon } from './3p-product-configs/box/icon';
import { CanvaFilterIcon } from './3p-product-configs/canva/icon';
import { CanvaIcon } from './3p-product-configs/canva/search-result-icon';
import { ClickUpFilterIcon } from './3p-product-configs/clickup/icon';
import { ClickUpIcon } from './3p-product-configs/clickup/search-result-icon';
import { DocusignFilterIcon } from './3p-product-configs/docusign/icon';
import { DocusignIcon } from './3p-product-configs/docusign/search-result-icon';
import { DovetailFilterIcon } from './3p-product-configs/dovetail/icon';
import { DovetailIcon } from './3p-product-configs/dovetail/search-result-icon';
import { DropboxIcon } from './3p-product-configs/dropbox/icon';
import { FigmaIcon } from './3p-product-configs/figma/icon';
import { FigmaSearchResultIcon } from './3p-product-configs/figma/search-result-icon';
import { FreshserviceFilterIcon } from './3p-product-configs/freshservice/icon';
import { FreshserviceIcon } from './3p-product-configs/freshservice/search-result-icon';
import { GithubIcon } from './3p-product-configs/github/icon';
import { GithubSearchResultIcon } from './3p-product-configs/github/search-result-icon';
import { GitlabFilterIcon } from './3p-product-configs/gitlab/icon';
import { GitlabIcon } from './3p-product-configs/gitlab/search-result-icon';
import { GmailIcon } from './3p-product-configs/gmail/icon';
import { GmailSearchResultIcon } from './3p-product-configs/gmail/search-result-icon';
import { GoogleCalendarIcon } from './3p-product-configs/google-calendar/icon';
import { GoogleDriveIcon } from './3p-product-configs/google/icon';
import { GoogleDriveSearchResultIcon } from './3p-product-configs/google/search-result-icon';
import { LoomIcon } from './3p-product-configs/loom/icon';
import { LucidFilterIcon } from './3p-product-configs/lucid/icon';
import { LucidIcon } from './3p-product-configs/lucid/search-result-icon';
import { MiroFilterIcon } from './3p-product-configs/miro/icon';
import { MiroIcon } from './3p-product-configs/miro/search-result-icon';
import { MondayFilterIcon } from './3p-product-configs/monday/icon';
import { MondayIcon } from './3p-product-configs/monday/search-result-icon';
import { MuralFilterIcon } from './3p-product-configs/mural/icon';
import { MuralIcon } from './3p-product-configs/mural/search-result-icon';
import { NotionFilterIcon } from './3p-product-configs/notion/icon';
import { NotionIcon } from './3p-product-configs/notion/search-result-icon';
import { OneDriveIcon } from './3p-product-configs/onedrive/icon';
import { OutlookCalendarIcon } from './3p-product-configs/outlook-calendar/icon';
import { OutlookMailIcon } from './3p-product-configs/outlook-mail/icon';
import { OutlookMailSearchResultIcon } from './3p-product-configs/outlook-mail/search-result-icon';
import { PagerDutyFilterIcon } from './3p-product-configs/pagerduty/icon';
import { PagerDutyIcon } from './3p-product-configs/pagerduty/search-result-icon';
import { PipedriveFilterIcon } from './3p-product-configs/pipedrive/icon';
import { PipedriveIcon } from './3p-product-configs/pipedrive/search-result-icon';
import { PowerBIFilterIcon } from './3p-product-configs/powerBI/icon';
import { PowerBIIcon } from './3p-product-configs/powerBI/search-result-icon';
import { ServiceNowFilterIcon } from './3p-product-configs/servicenow/icon';
import { ServiceNowIcon } from './3p-product-configs/servicenow/search-result-icon';
import { SharePointIcon } from './3p-product-configs/sharepoint/icon';
import { SharePointSearchResultIcon } from './3p-product-configs/sharepoint/search-result-icon';
import { SlackIcon } from './3p-product-configs/slack/icon';
import { SlackSearchResultIcon } from './3p-product-configs/slack/search-result-icon';
import { SmartsheetFilterIcon } from './3p-product-configs/smartsheet/icon';
import { SmartsheetIcon } from './3p-product-configs/smartsheet/search-result-icon';
import { StripeFilterIcon } from './3p-product-configs/stripe/icon';
import { StripeIcon } from './3p-product-configs/stripe/search-result-icon';
import { TeamsIcon } from './3p-product-configs/teams/icon';
import { TeamsSearchResultIcon } from './3p-product-configs/teams/search-result-icon';
import { TodoistFilterIcon } from './3p-product-configs/todoist/icon';
import { TodoistIcon } from './3p-product-configs/todoist/search-result-icon';
import { WebexFilterIcon } from './3p-product-configs/webex/icon';
import { WebexIcon } from './3p-product-configs/webex/search-result-icon';
import { ZendeskFilterIcon } from './3p-product-configs/zendesk/icon';
import { ZendeskIcon } from './3p-product-configs/zendesk/search-result-icon';
import { ZeplinFilterIcon } from './3p-product-configs/zeplin/icon';
import { ZeplinIcon } from './3p-product-configs/zeplin/search-result-icon';
import { type RovoLogo, RovoLogoFromGlyph, RovoLogoFromLogo } from './icons';
import { AtlasSearchResultIcon } from './icons/atlas-search';
import { ConfluenceSearchResultIcon } from './icons/confluence-search';
import { JiraSearchResultIcon } from './icons/jira-search';
import { messages } from './messages';

export interface ProductConfig {
	displayName: React.ReactNode;
	Icon: RovoLogo;
	is3P?: boolean;
	SearchResultIcon?: RovoLogo;
}

const ProductKeys1P = {
	Atlas: 'townsquare',
	Bitbucket: 'bitbucket',
	Compass: 'compass',
	Confluence: 'confluence',
	Jira: 'jira',
	JiraProductDiscovery: 'jira-product-discovery',
	JiraServiceManagement: 'jira-servicedesk',
	Trello: 'trello',
	Focus: 'mercury',
	// Can't add these until they're ready as we use this type to enforce that product configs are available for all products
	// Opsgenie: 'opsgenie',
} as const;

export const ProductKeys3P = DO_NOT_IMPORT_ProductKeys3P;

export const ProductKeys = {
	...ProductKeys1P,
	...DO_NOT_IMPORT_ProductKeys3P,
} as const;

export type ProductKeys3P =
	(typeof DO_NOT_IMPORT_ProductKeys3P)[keyof typeof DO_NOT_IMPORT_ProductKeys3P];
export type ProductKeys1P = (typeof ProductKeys1P)[keyof typeof ProductKeys1P];
export type ProductKeys = (typeof ProductKeys)[keyof typeof ProductKeys];
export const allProductKeys: ProductKeys[] = Object.values(ProductKeys);
export const all3pProductKeys: ProductKeys3P[] = Object.values(DO_NOT_IMPORT_ProductKeys3P);
export const all1pProductKeys: ProductKeys1P[] = Object.values(ProductKeys1P);
export const is1pProductKey = (key: string): key is ProductKeys1P =>
	all1pProductKeys.includes(key as ProductKeys1P);
export const is3pProductKey = (key: string): key is ProductKeys3P =>
	all3pProductKeys.includes(key as ProductKeys3P);

export const PRODUCTS_ORDER: ProductKeys[] = [
	ProductKeys.Confluence,
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
	ProductKeys.Atlas,
	ProductKeys.Trello,
	ProductKeys.Bitbucket,
	ProductKeys.Compass,
	ProductKeys.Focus,
	// ProductKeys.Opsgenie,

	...DO_NOT_IMPORT_ProductsOrder3P,
];

// This is different to ProductKeys, it represents products we have support for mounting Search Page in
export const PrimaryProductKeys = {
	// Fully supported as GA
	Atlas: 'townsquare',
	Confluence: 'confluence',
	// In development
	Assets: 'assets',
	Focus: 'mercury',
	Jira: 'jira',
	Elevate: 'elevate',
} as const;
export type PrimaryProductKey = (typeof PrimaryProductKeys)[keyof typeof PrimaryProductKeys];

export const originalProducts3P: Record<string, ProductConfig> = {
	[ProductKeys.GoogleDrive]: {
		displayName: 'Google Drive',
		Icon: RovoLogoFromGlyph(GoogleDriveIcon, 'Google Drive'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(GoogleDriveSearchResultIcon, 'Google Drive'),
	},
	[ProductKeys.Sharepoint]: {
		displayName: 'Microsoft SharePoint',
		Icon: RovoLogoFromGlyph(SharePointIcon, 'Sharepoint'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(SharePointSearchResultIcon, 'Sharepoint'),
	},
	[ProductKeys.AtlassianWebCrawler]: {
		displayName: <FormattedMessage {...messages.webPage} />,
		Icon: RovoLogoFromLogo(GlobeIcon, 'Atlassian Web Crawler'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(WebDomainSearchResultIcon, 'Atlassian Web Crawler'),
	},
	[ProductKeys.Teams]: {
		displayName: 'Teams',
		Icon: RovoLogoFromGlyph(TeamsIcon, 'Teams'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(TeamsSearchResultIcon, 'Teams'),
	},
	[ProductKeys.Loom]: {
		displayName: 'Loom',
		Icon: RovoLogoFromGlyph(LoomIcon, 'Loom'),
		is3P: true,
	},
	[ProductKeys.OneDrive]: {
		displayName: 'OneDrive',
		Icon: RovoLogoFromGlyph(OneDriveIcon, 'OneDrive'),
		is3P: true,
	},
	[ProductKeys.Slack]: {
		displayName: 'Slack',
		Icon: RovoLogoFromGlyph(SlackIcon, 'Slack'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(SlackSearchResultIcon, 'Slack'),
	},
	[ProductKeys.Github]: {
		displayName: 'GitHub',
		Icon: RovoLogoFromGlyph(GithubIcon, 'Github'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(GithubSearchResultIcon, 'Github'),
	},
	[ProductKeys.Figma]: {
		displayName: 'Figma',
		Icon: RovoLogoFromGlyph(FigmaIcon, 'Figma'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(FigmaSearchResultIcon, 'Figma'),
	},
	[ProductKeys.Dropbox]: {
		displayName: 'Dropbox',
		Icon: RovoLogoFromGlyph(DropboxIcon, 'Dropbox'),
		is3P: true,
	},
	[ProductKeys.Box]: {
		displayName: 'Box',
		Icon: RovoLogoFromGlyph(BoxIcon, 'Box'),
		is3P: true,
	},
	[ProductKeys.GoogleCalendar]: {
		displayName: 'Google Calendar',
		Icon: RovoLogoFromGlyph(GoogleCalendarIcon, 'Google Calendar'),
		is3P: true,
	},
	[ProductKeys.Gmail]: {
		displayName: 'Gmail',
		Icon: RovoLogoFromGlyph(GmailIcon, 'Gmail'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(GmailSearchResultIcon, 'Gmail'),
	},
	[ProductKeys.OutlookCalendar]: {
		displayName: 'Outlook Calendar',
		Icon: RovoLogoFromGlyph(OutlookCalendarIcon, 'Outlook Calendar'),
		is3P: true,
	},
	[ProductKeys.OutlookMail]: {
		displayName: 'Outlook Mail',
		Icon: RovoLogoFromGlyph(OutlookMailIcon, 'Outlook Mail'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(OutlookMailSearchResultIcon, 'Outlook Mail'),
	},
	[ProductKeys.Airtable]: {
		displayName: 'Airtable',
		Icon: RovoLogoFromGlyph(AirtableFilterIcon, 'Airtable'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AirtableIcon, 'Airtable'),
	},
	[ProductKeys.Smartsheet]: {
		displayName: 'Smartsheet',
		Icon: RovoLogoFromGlyph(SmartsheetFilterIcon, 'Smartsheet'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(SmartsheetIcon, 'Smartsheet'),
	},
	[ProductKeys.Asana]: {
		displayName: 'Asana',
		Icon: RovoLogoFromGlyph(AsanaFilterIcon, 'Asana'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AsanaIcon, 'Asana'),
	},
	[ProductKeys.Zendesk]: {
		displayName: 'Zendesk',
		Icon: RovoLogoFromGlyph(ZendeskFilterIcon, 'Zendesk'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(ZendeskIcon, 'Zendesk'),
	},
	[ProductKeys.Notion]: {
		displayName: 'Notion',
		Icon: RovoLogoFromGlyph(NotionFilterIcon, 'Notion'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(NotionIcon, 'Notion'),
	},
	[ProductKeys.Miro]: {
		displayName: 'Miro',
		Icon: RovoLogoFromGlyph(MiroFilterIcon, 'Miro'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(MiroIcon, 'Miro'),
	},
	[ProductKeys.Docusign]: {
		displayName: 'Docusign',
		Icon: RovoLogoFromGlyph(DocusignFilterIcon, 'Docusign'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(DocusignIcon, 'Docusign'),
	},
	[ProductKeys.AzureDevops]: {
		displayName: 'Azure DevOps',
		Icon: RovoLogoFromGlyph(AzureDevopsFilterIcon, 'AzureDevops'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AzureDevopsIcon, 'AzureDevops'),
	},
	[ProductKeys.Lucid]: {
		displayName: 'Lucid',
		Icon: RovoLogoFromGlyph(LucidFilterIcon, 'Lucid'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(LucidIcon, 'Lucid'),
	},
	[ProductKeys.Monday]: {
		displayName: 'Monday',
		Icon: RovoLogoFromGlyph(MondayFilterIcon, 'Monday'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(MondayIcon, 'Monday'),
	},
	[ProductKeys.Canva]: {
		displayName: 'Canva',
		Icon: RovoLogoFromGlyph(CanvaFilterIcon, 'Canva'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(CanvaIcon, 'Canva'),
	},
	[ProductKeys.Gitlab]: {
		displayName: 'Gitlab',
		Icon: RovoLogoFromGlyph(GitlabFilterIcon, 'Gitlab'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(GitlabIcon, 'Gitlab'),
	},
	[ProductKeys.ConfluenceDC]: {
		displayName: 'Confluence Data Center',
		Icon: RovoLogoFromLogo(ConfluenceIcon, 'Confluence Data Center'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(ConfluenceSearchResultIcon, 'Confluence'),
	},
	[ProductKeys.Zeplin]: {
		displayName: 'Zeplin',
		Icon: RovoLogoFromGlyph(ZeplinFilterIcon, 'Zeplin'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(ZeplinIcon, 'Zeplin'),
	},
	[ProductKeys.ServiceNow]: {
		displayName: 'ServiceNow',
		Icon: RovoLogoFromGlyph(ServiceNowFilterIcon, 'ServiceNow'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(ServiceNowIcon, 'ServiceNow'),
	},
	[ProductKeys.Freshservice]: {
		displayName: 'Freshservice',
		Icon: RovoLogoFromGlyph(FreshserviceFilterIcon, 'Freshservice'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(FreshserviceIcon, 'Freshservice'),
	},
	[ProductKeys.Stripe]: {
		displayName: 'Stripe',
		Icon: RovoLogoFromGlyph(StripeFilterIcon, 'Stripe'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(StripeIcon, 'Stripe'),
	},
	[ProductKeys.PowerBI]: {
		displayName: 'PowerBI',
		Icon: RovoLogoFromGlyph(PowerBIFilterIcon, 'PowerBI'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(PowerBIIcon, 'PowerBI'),
	},
	[ProductKeys.ClickUp]: {
		displayName: 'ClickUp',
		Icon: RovoLogoFromGlyph(ClickUpFilterIcon, 'ClickUp'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(ClickUpIcon, 'ClickUp'),
	},
	[ProductKeys.Amplitude]: {
		displayName: 'Amplitude',
		Icon: RovoLogoFromGlyph(AmplitudeFilterIcon, 'Amplitude'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AmplitudeIcon, 'Amplitude'),
	},
	[ProductKeys.AdobeXD]: {
		displayName: 'AdobeXD',
		Icon: RovoLogoFromGlyph(AdobeXDFilterIcon, 'AdobeXD'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AdobeXDIcon, 'AdobeXD'),
	},
	[ProductKeys.Aha]: {
		displayName: 'Aha',
		Icon: RovoLogoFromGlyph(AhaFilterIcon, 'Aha'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AhaIcon, 'Aha'),
	},
	[ProductKeys.AdobeSign]: {
		displayName: 'Adobe Sign',
		Icon: RovoLogoFromGlyph(AdobeSignFilterIcon, 'AdobeSign'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(AdobeSignIcon, 'AdobeSign'),
	},
	[ProductKeys.Mural]: {
		displayName: 'Mural',
		Icon: RovoLogoFromGlyph(MuralFilterIcon, 'Mural'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(MuralIcon, 'Mural'),
	},
	[ProductKeys.Webex]: {
		displayName: 'Webex',
		Icon: RovoLogoFromGlyph(WebexFilterIcon, 'Webex'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(WebexIcon, 'Webex'),
	},
	[ProductKeys.Todoist]: {
		displayName: 'Todoist',
		Icon: RovoLogoFromGlyph(TodoistFilterIcon, 'Todoist'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(TodoistIcon, 'Todoist'),
	},
	[ProductKeys.PagerDuty]: {
		displayName: 'PagerDuty',
		Icon: RovoLogoFromGlyph(PagerDutyFilterIcon, 'PagerDuty'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(PagerDutyIcon, 'PagerDuty'),
	},
	[ProductKeys.Pipedrive]: {
		displayName: 'Pipedrive',
		Icon: RovoLogoFromGlyph(PipedriveFilterIcon, 'Pipedrive'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(PipedriveIcon, 'Pipedrive'),
	},
	[ProductKeys.Dovetail]: {
		displayName: 'Dovetail',
		Icon: RovoLogoFromGlyph(DovetailFilterIcon, 'Dovetail'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(DovetailIcon, 'Dovetail'),
	},
};

/**
 * This is the source of truth for the products that are supported in Full Page Search.
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * QS-5444 3P references in this object are being deprecated. Please add to ./3p-product-configs
 */
const products1P: Record<ProductKeys1P, ProductConfig> = {
	[ProductKeys.Confluence]: {
		displayName: 'Confluence',
		Icon: RovoLogoFromLogo(ConfluenceIcon, 'Confluence'),
		SearchResultIcon: RovoLogoFromGlyph(ConfluenceSearchResultIcon, 'Confluence'),
	},
	[ProductKeys.Atlas]: {
		displayName: 'Atlas',
		Icon: RovoLogoFromLogo(AtlassianIcon, 'Atlas'),
		SearchResultIcon: RovoLogoFromGlyph(AtlasSearchResultIcon, 'Atlas'),
	},
	[ProductKeys.Jira]: {
		displayName: 'Jira',
		Icon: RovoLogoFromLogo(JiraIcon, 'Jira'),
		SearchResultIcon: RovoLogoFromGlyph(JiraSearchResultIcon, 'Jira'),
	},
	[ProductKeys.JiraProductDiscovery]: {
		displayName: 'Jira Product Discovery',
		Icon: RovoLogoFromLogo(JiraProductDiscoveryIcon, 'Jira Product Discovery'),
	},
	[ProductKeys.JiraServiceManagement]: {
		displayName: 'Jira Service Management',
		Icon: RovoLogoFromLogo(JiraServiceManagementIcon, 'Jira Service Management'),
	},
	[ProductKeys.Bitbucket]: {
		displayName: 'Bitbucket',
		Icon: RovoLogoFromLogo(BitbucketIcon, 'Bitbucket'),
	},
	[ProductKeys.Trello]: {
		displayName: 'Trello',
		Icon: RovoLogoFromLogo(TrelloIcon, 'Trello'),
	},
	[ProductKeys.Compass]: {
		displayName: 'Compass',
		Icon: RovoLogoFromLogo(CompassIcon, 'Compass'),
	},
	[ProductKeys.Focus]: {
		displayName: 'Focus',
		Icon: RovoLogoFromLogo(FocusIcon, 'Focus'),
	},
};

const getProducts3PFromConfig = (): Record<ProductKeys3P, ProductConfig> => {
	// The use of Partial, the if (config) and the cast here are intentional TS gymnastics
	// We KNOW that this returns a config for all the ProductKeys3P, so we cast that
	// The TS compiler can't know that, and we'd be forced to write UI code like:
	//
	// const Icon = configs[productKey].Icon
	// return Icon && <Icon />
	//
	// Because TS can't prove productKey can be guaranteed to index into configs. But we know it can!
	const thirdPartyProducts = ThirdPartyConfigs;
	const configs: Partial<Record<ProductKeys3P, ProductConfig>> = {};

	all3pProductKeys.map((productKey3P) => {
		const config = thirdPartyProducts.get(productKey3P);
		if (config) {
			configs[productKey3P] = {
				displayName: config.displayName,
				Icon: config.icon,
				is3P: true,
				...(config.searchResultIcon && { SearchResultIcon: config.searchResultIcon }),
			};
		}
	});

	return configs as Record<ProductKeys3P, ProductConfig>;
};

/**
 * This is the source of truth for the products that are supported in Full Page Search for use by the UI
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * If you just need the set of productKeys, please import { allProductKeys } or other similar helpers
 *
 * To get the list of products available to a user (a state concern) please see the exports in store/search
 *
 * QS-5444 3P references in this object are being deprecated. Please add to ./3p-product-configs
 */
export const useProductConfigs = (): Record<ProductKeys, ProductConfig> => {
	const staticConfigFg = fg('rovo-full-page-search-3p-static-config');

	return useMemo(() => {
		const thirdPartyProducts = staticConfigFg ? getProducts3PFromConfig() : originalProducts3P;

		return {
			...products1P,
			...thirdPartyProducts,
		};
	}, [staticConfigFg]);
};

export const useProductConfig = (productKey: ProductKeys): ProductConfig => {
	return useProductConfigs()[productKey];
};

/**
 * If you're in a react component, please use useProductConfig(s) hooks instead
 **/
export const getProductConfig = (productKey: ProductKeys): ProductConfig => {
	const staticConfigFg = fg('rovo-full-page-search-3p-static-config');

	const thirdPartyProducts = staticConfigFg ? getProducts3PFromConfig() : originalProducts3P;

	const products = {
		...products1P,
		...thirdPartyProducts,
	};

	return products[productKey];
};

/**
 * Deprecated! Kept for testing
 * Remove when cleaning up rovo-full-page-search-3p-static-config
 *
 * Please use import useProductConfig(s) hooks instead
 */
export const originalProducts: Partial<Record<ProductKeys, ProductConfig>> = {
	...products1P,
	...originalProducts3P,
};
