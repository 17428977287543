import React from 'react';

export const PagerDutyIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M27.9962 16.0002C27.9962 19.935 27.9953 23.8702 28.0005 27.805C28.0005 27.9587 27.9784 28.0005 27.812 28C19.9416 27.9944 12.0706 27.9944 4.20016 28C4.02344 28.0005 4 27.9545 4 27.7933C4.00469 19.9312 4.00469 12.0692 4 4.20719C4 4.04641 4.0225 4 4.20016 4C12.0631 4.00609 19.9256 4.00609 27.7886 4C27.9709 4 28.0009 4.04781 28.0009 4.21844C27.9958 8.14571 27.9967 12.0725 27.9967 15.9998L27.9962 16.0002Z"
			fill="#04AB38"
		/>
		<path
			d="M11.0196 12.8374C11.0196 11.2919 11.0206 9.74641 11.0178 8.20091C11.0178 8.06216 11.0159 7.97591 11.2062 7.97731C13.1187 7.98856 15.0313 7.9745 16.9438 7.99747C18.0262 8.01059 19.0589 8.26794 19.9912 8.83936C21.1331 9.53828 21.7692 10.5794 21.9713 11.8886C22.1878 13.2926 21.96 14.6093 21.097 15.7662C20.3629 16.7511 19.3401 17.2714 18.1509 17.5011C17.4112 17.6441 16.6626 17.6628 15.9144 17.6684C14.3535 17.6806 12.792 17.6698 11.2306 17.6783C11.0454 17.6792 11.015 17.6164 11.0159 17.4509C11.0215 15.9134 11.0192 14.3754 11.0192 12.8379L11.0196 12.8374Z"
			fill="#FEFEFE"
		/>
		<path
			d="M11.0328 21.8176C11.0328 21.1782 11.0361 20.5383 11.03 19.8989C11.0286 19.7597 11.0459 19.6945 11.2142 19.6974C11.877 19.7086 12.5403 19.7072 13.2031 19.6974C13.3569 19.695 13.3789 19.7489 13.3785 19.8839C13.3738 21.1866 13.3728 22.4893 13.3794 23.7915C13.3803 23.9429 13.3316 23.9696 13.1942 23.9682C12.5314 23.9617 11.8681 23.9598 11.2053 23.9692C11.0515 23.9715 11.0286 23.9181 11.03 23.7826C11.0365 23.1273 11.0328 22.4724 11.0328 21.8171V21.8176Z"
			fill="#FEFEFE"
		/>
		<path
			d="M13.3686 12.8379C13.3686 11.9712 13.3705 11.1049 13.3663 10.2382C13.3658 10.1102 13.3776 10.0441 13.5379 10.045C14.6076 10.053 15.6773 10.0319 16.7465 10.0568C17.3564 10.0708 17.9508 10.2086 18.4898 10.5175C19.1977 10.923 19.538 11.5582 19.6097 12.3518C19.6627 12.9401 19.6369 13.5209 19.3842 14.0693C18.9811 14.9445 18.2311 15.3383 17.3395 15.5295C16.9415 15.6148 16.5365 15.6345 16.1301 15.6345C15.2868 15.634 14.4435 15.6265 13.6007 15.6401C13.3954 15.6434 13.3621 15.5764 13.3644 15.3912C13.3743 14.5404 13.3686 13.6892 13.3686 12.8384V12.8379Z"
			fill="#04AB38"
		/>
	</svg>
);
