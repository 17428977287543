import React from 'react';

export const SharePointIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_271_181641)">
			<g clipPath="url(#clip1_271_181641)">
				<path
					d="M12.2328 13.4286C15.3153 13.4286 17.8142 10.8702 17.8142 7.71429C17.8142 4.55837 15.3153 2 12.2328 2C9.15024 2 6.65137 4.55837 6.65137 7.71429C6.65137 10.8702 9.15024 13.4286 12.2328 13.4286Z"
					fill="#036C70"
				/>
				<path
					d="M16.8836 18.1906C19.7093 18.1906 21.9999 15.8454 21.9999 12.9525C21.9999 10.0595 19.7093 7.71436 16.8836 7.71436C14.058 7.71436 11.7673 10.0595 11.7673 12.9525C11.7673 15.8454 14.058 18.1906 16.8836 18.1906Z"
					fill="#1A9BA1"
				/>
				<path
					d="M12.9303 22C15.1137 22 16.8838 20.1878 16.8838 17.9524C16.8838 15.717 15.1137 13.9048 12.9303 13.9048C10.7468 13.9048 8.97681 15.717 8.97681 17.9524C8.97681 20.1878 10.7468 22 12.9303 22Z"
					fill="#37C6D0"
				/>
				<path
					opacity="0.1"
					d="M13.1628 7.15707V17.319C13.1605 17.6721 12.9515 17.9893 12.6326 18.1237C12.5311 18.1677 12.4219 18.1904 12.3117 18.1904H8.98144C8.9768 18.1094 8.9768 18.0333 8.9768 17.9523C8.97525 17.8728 8.97835 17.7933 8.9861 17.7142C9.07123 16.192 9.98715 14.849 11.3535 14.2428V13.3571C8.31257 12.8637 6.23802 9.93985 6.71991 6.82647C6.72326 6.8049 6.72671 6.78335 6.73029 6.76183C6.75344 6.60124 6.78607 6.44221 6.82797 6.28564H12.3117C12.781 6.28747 13.1611 6.67656 13.1628 7.15707Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8464 6.76196H6.73014C6.2133 9.86973 8.25507 12.818 11.2906 13.3472C11.3825 13.3632 11.4748 13.3769 11.5673 13.3882C10.1255 14.0882 9.07479 16.0762 8.98549 17.7143C8.97775 17.7934 8.97463 17.8729 8.97618 17.9524C8.97618 18.0334 8.97618 18.1096 8.98083 18.1905C8.98922 18.3506 9.00944 18.5098 9.04129 18.6667H11.8459C12.1908 18.6644 12.5007 18.4504 12.632 18.1239C12.6749 18.0199 12.6971 17.9082 12.6971 17.7953V7.63339C12.6953 7.15306 12.3156 6.76406 11.8464 6.76196Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8466 6.76196H6.73034C6.21361 9.87002 8.25569 12.8185 11.2915 13.3475C11.3536 13.3583 11.4158 13.3681 11.4783 13.3767C10.0829 14.1272 9.07361 16.1105 8.98616 17.7143H11.8466C12.3152 17.7107 12.6942 17.3227 12.6978 16.8429V7.63339C12.696 7.15288 12.316 6.76379 11.8466 6.76196Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.3814 6.76196H6.73019C6.24226 9.696 8.03799 12.5215 10.8558 13.2534C9.78883 14.5014 9.13442 16.0628 8.98601 17.7143H11.3814C11.8507 17.7125 12.2307 17.3234 12.2325 16.8429V7.63339C12.2323 7.15223 11.8513 6.76223 11.3814 6.76196Z"
					fill="black"
				/>
				<path
					d="M2.85256 6.76196H11.38C11.8509 6.76196 12.2326 7.15275 12.2326 7.63482V16.3653C12.2326 16.8474 11.8509 17.2382 11.38 17.2382H2.85256C2.3817 17.2382 2 16.8474 2 16.3653V7.63482C2 7.15275 2.3817 6.76196 2.85256 6.76196Z"
					fill="#04868B"
				/>
				<path
					d="M5.80776 11.8932C5.60797 11.7575 5.44108 11.5769 5.31985 11.3651C5.20239 11.1437 5.14385 10.8946 5.15008 10.6427C5.13962 10.3017 5.25202 9.96868 5.46589 9.70702C5.69064 9.44505 5.98113 9.25105 6.30589 9.14606C6.67602 9.02134 7.0637 8.95988 7.45333 8.96416C7.96573 8.945 8.47741 9.01835 8.96496 9.18083V10.2761C8.75312 10.1447 8.52238 10.0483 8.28124 9.99036C8.01958 9.92467 7.7511 9.89173 7.4817 9.89226C7.19761 9.88158 6.91534 9.94276 6.65984 10.0704C6.46259 10.1575 6.33467 10.3561 6.33426 10.5761C6.33346 10.7095 6.38352 10.838 6.4738 10.9342C6.58043 11.0476 6.70656 11.1399 6.84589 11.2066C7.00093 11.2856 7.23349 11.3907 7.54357 11.5218C7.57771 11.5328 7.61099 11.5465 7.6431 11.5628C7.94828 11.6849 8.24269 11.8335 8.5231 12.007C8.73546 12.1411 8.91368 12.3249 9.04311 12.5432C9.1758 12.7908 9.24011 13.0706 9.22915 13.3528C9.2443 13.7029 9.13968 14.0474 8.93334 14.327C8.72767 14.5841 8.45269 14.7736 8.14264 14.8718C7.77795 14.9888 7.39733 15.0454 7.01519 15.0394C6.67234 15.041 6.32999 15.0123 5.99194 14.9537C5.70651 14.9059 5.42796 14.8221 5.16264 14.7042V13.5494C5.41625 13.7349 5.69959 13.8735 5.99985 13.9589C6.2991 14.0544 6.61017 14.1054 6.92357 14.1104C7.21363 14.1292 7.50318 14.0663 7.76078 13.9285C7.94123 13.8242 8.05098 13.6267 8.04637 13.4147C8.04757 13.2671 7.99055 13.1253 7.88823 13.0213C7.76098 12.8934 7.61366 12.7883 7.45242 12.7104C7.26638 12.6151 6.99242 12.4896 6.63056 12.3337C6.34269 12.2152 6.06706 12.0677 5.80776 11.8932Z"
					fill="white"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_271_181641">
				<rect width="20" height="20" fill="white" transform="translate(2 2)" />
			</clipPath>
			<clipPath id="clip1_271_181641">
				<rect width="20" height="20" fill="white" transform="translate(2 2)" />
			</clipPath>
		</defs>
	</svg>
);
