import React from 'react';
export const ServiceNowIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0709 5.00321C13.6573 4.99818 11.2979 5.7183 9.29852 7.07021C7.29912 8.42213 5.75194 10.3435 4.85757 12.5852C3.96319 14.827 3.76287 17.2857 4.28256 19.6426C4.80225 21.9996 6.01798 24.1461 7.77216 25.8038C8.18362 26.1956 8.72173 26.4272 9.28912 26.4566C9.85651 26.4861 10.4157 26.3115 10.8655 25.9644C12.3496 24.86 14.1503 24.2635 16.0002 24.2635C17.8502 24.2635 19.6508 24.86 21.1349 25.9644C21.589 26.3123 22.1531 26.4853 22.7242 26.452C23.2952 26.4187 23.8353 26.1812 24.2459 25.7829C25.9864 24.1313 27.1939 21.9976 27.7138 19.6552C28.2337 17.3129 28.0422 14.8686 27.164 12.6358C26.2857 10.403 24.7606 8.4833 22.7842 7.12287C20.8078 5.76244 18.4701 5.02323 16.0709 5M16.0002 23.0574C15.207 23.0788 14.4176 22.9384 13.6804 22.6447C12.9432 22.351 12.2736 21.9102 11.7124 21.3492C11.1512 20.7881 10.7102 20.1186 10.4163 19.3815C10.1224 18.6443 9.9818 17.8551 10.003 17.0618C10.003 15.4713 10.6349 13.9459 11.7596 12.8212C12.8843 11.6965 14.4097 11.0646 16.0002 11.0646C17.5908 11.0646 19.1162 11.6965 20.2409 12.8212C21.3655 13.9459 21.9974 15.4713 21.9974 17.0618C22.0186 17.8551 21.878 18.6443 21.5841 19.3815C21.2902 20.1186 20.8492 20.7881 20.288 21.3492C19.7268 21.9102 19.0572 22.351 18.32 22.6447C17.5828 22.9384 16.7935 23.0788 16.0002 23.0574Z"
			fill="#62D84E"
		/>
	</svg>
);
