import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MondayFilterIcon } from './icon';
import { MondayIcon } from './search-result-icon';

export const mondayConfig: ThirdPartyConfig = {
	displayName: 'Monday',
	id: 'monday',
	resourceType: 'monday-connector',
	icon: RovoLogoFromGlyph(MondayFilterIcon, 'Monday'),
	searchResultIcon: RovoLogoFromGlyph(MondayIcon, 'Monday'),
	integrationAri: 'ari:cloud:platform::integration/monday',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
