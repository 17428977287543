import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AzureDevopsFilterIcon } from './icon';
import { AzureDevopsIcon } from './search-result-icon';

export const azureDevopsConfig: ThirdPartyConfig = {
	displayName: 'Azure DevOps',
	id: 'azure-devops',
	resourceType: 'azure-devops-connector',
	icon: RovoLogoFromGlyph(AzureDevopsFilterIcon, 'AzureDevOps'),
	searchResultIcon: RovoLogoFromGlyph(AzureDevopsIcon, 'AzureDevOps'),
	integrationAri: 'ari:cloud:platform::integration/azure-devops',
	typeFilterValues: [
		TypeFilterValueKey.Repository,
		TypeFilterValueKey.PullRequest,
		TypeFilterValueKey.Branch,
		TypeFilterValueKey.Commit,
	],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
