import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { SmartsheetFilterIcon } from './icon';
import { SmartsheetIcon } from './search-result-icon';

export const smartsheetConfig: ThirdPartyConfig = {
	displayName: 'Smartsheet',
	id: 'smartsheet',
	resourceType: 'smartsheet-connector',
	icon: RovoLogoFromGlyph(SmartsheetFilterIcon, 'Smartsheet'),
	searchResultIcon: RovoLogoFromGlyph(SmartsheetIcon, 'Smartsheet'),
	integrationAri: 'ari:cloud:platform::integration/smartsheet',
	typeFilterValues: [TypeFilterValueKey.Document],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => false,
};
