import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { StripeFilterIcon } from './icon';
import { StripeIcon } from './search-result-icon';

export const stripeConfig: ThirdPartyConfig = {
	displayName: 'Stripe',
	id: 'stripe',
	resourceType: 'smartlinks-connector-stripe',
	icon: RovoLogoFromGlyph(StripeFilterIcon, 'Stripe'),
	searchResultIcon: RovoLogoFromGlyph(StripeIcon, 'Stripe'),
	integrationAri: 'ari:cloud:platform::integration/stripe',
	typeFilterValues: [TypeFilterValueKey.Invoice],
	requiresOauth: true,
	isEnabled: () => false,
};
