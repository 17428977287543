import React from 'react';

export const ZeplinFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.46609 14.5173L5.62903 14.8128L5.58423 17.2177L8.48154 16.1957C7.48768 15.8732 6.76209 15.3058 6.46609 14.5173Z"
			fill="#FDBD39"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.90398 11.9736L4 12.9983L5.62932 14.8129L6.46612 14.5174C6.17092 13.7313 6.35278 12.8429 6.90398 11.9736Z"
			fill="#F69833"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7914 8.50318C8.09303 9.80805 5.70878 12.5007 6.46611 14.5175L19.8583 9.79208C19.101 7.77527 15.4895 7.19831 11.7914 8.50318Z"
			fill="#FECF33"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.5332 15.8063C18.2313 14.5012 20.6199 11.8206 19.8585 9.79199L6.46582 14.5174C7.22741 16.5461 10.8349 17.1112 14.5332 15.8063Z"
			fill="#EE6723"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.8585 9.79199L6.46582 14.5174C6.72795 15.216 7.72821 15.5601 9.13113 15.5601C10.4991 15.5601 12.2503 15.2332 14.0759 14.589C17.774 13.2842 20.3759 11.1713 19.858 9.79199"
			fill="#F69833"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.1929 8.74951C15.8249 8.74951 14.0737 9.07637 12.2482 9.72057C8.55005 11.0254 5.94821 13.1381 6.46607 14.5176L19.8582 9.79217C19.5961 9.09363 18.5961 8.74951 17.1929 8.74951Z"
			fill="#FDBD39"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.4533 17.3454C16.0591 17.3454 15.4781 17.0016 14.7253 16.3236C13.8354 15.5216 12.8157 14.3468 11.8541 13.0154C10.8928 11.6845 10.1026 10.3536 9.62958 9.26818C9.11439 8.0854 9.04079 7.35827 9.41118 7.10662C9.49252 7.0515 9.59038 7.02368 9.70238 7.02368C10.0037 7.02368 10.5909 7.2403 11.6765 8.27292L11.7005 8.29532L11.697 8.32752C11.6896 8.4017 11.6829 8.47666 11.6768 8.55007L11.6648 8.69585L11.5581 8.59231C10.3882 7.45383 9.87331 7.33097 9.70611 7.33097C9.65838 7.33097 9.62052 7.34024 9.59332 7.35879C9.43252 7.46774 9.42212 8.00195 9.92185 9.14841C10.3866 10.2145 11.165 11.525 12.1138 12.8389C13.0632 14.1531 14.0672 15.3106 14.9415 16.0983C15.8842 16.9477 16.309 17.0394 16.4482 17.0394C16.4962 17.0394 16.5349 17.0297 16.5639 17.0098C16.7333 16.8949 16.7335 16.3226 16.1762 15.0883L16.1506 15.0319L16.2066 15.0023C16.2626 14.9727 16.317 14.9436 16.3677 14.9157L16.4349 14.8789L16.4658 14.9472C16.8607 15.8216 17.2301 16.9331 16.7458 17.262C16.6642 17.3174 16.5658 17.3454 16.4533 17.3454Z"
			fill="#EE6723"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6229 15.7695L14.6175 15.8365C14.5239 16.9541 14.2775 18.2974 13.6357 18.352C13.6229 18.353 13.6095 18.3535 13.5962 18.3535C13.1599 18.3535 12.7474 17.75 12.3701 16.56C12.0128 15.4339 11.7354 13.9225 11.5896 12.3031C11.4434 10.6845 11.4456 9.14884 11.5957 7.97971C11.7592 6.7055 12.0706 6.04018 12.5213 6.0018C12.5346 6.00077 12.5485 6 12.5621 6C12.8784 6 13.3551 6.34618 13.8431 7.95756L13.5245 8.0168C13.3615 7.47847 13.1839 7.0406 13.0077 6.73924C12.8445 6.46106 12.6861 6.3078 12.5613 6.3078C12.557 6.3078 12.553 6.3078 12.5488 6.30832C12.3536 6.3248 12.069 6.78251 11.9106 8.01758C11.7632 9.16584 11.7613 10.6783 11.9053 12.2763C12.0498 13.8746 12.3224 15.3638 12.6733 16.4696C12.8304 16.9647 13.0026 17.3729 13.1717 17.6498C13.3274 17.9053 13.4781 18.046 13.5959 18.046C13.5999 18.046 13.6039 18.046 13.6082 18.0454C13.7949 18.0297 14.1471 17.5535 14.2954 15.878L14.6229 15.7695Z"
			fill="#EE6723"
		/>
	</svg>
);
