import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GoogleDriveIcon } from './icon';
import { GoogleDriveSearchResultIcon } from './search-result-icon';

// static 3P config object for google drive
export const googleDriveConfig: ThirdPartyConfig = {
	displayName: 'Google Drive',
	id: 'drive',
	resourceType: 'google-drive-connector',
	icon: RovoLogoFromGlyph(GoogleDriveIcon, 'Google Drive'),
	searchResultIcon: RovoLogoFromGlyph(GoogleDriveSearchResultIcon, 'Google Drive'),
	integrationAri: 'ari:cloud:platform::integration/google',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
