import React from 'react';

export const BoxSearchResultIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_7_563)">
			<path
				d="M27.239 21.239C27.5317 21.6488 27.4732 22.1756 27.122 22.4683C26.7122 22.761 26.1268 22.7024 25.8341 22.3512L23.7854 19.7171L21.7951 22.2927C21.5024 22.7024 20.9171 22.7024 20.5073 22.4098C20.0976 22.1171 20.039 21.5902 20.3317 21.1805L22.6732 18.1366L20.3317 15.0927C20.039 14.6829 20.1561 14.0976 20.5073 13.8049C20.9171 13.5122 21.5024 13.6293 21.7951 13.9805L23.7854 16.6146L25.8341 14.0976C26.1268 13.6878 26.6537 13.6293 27.122 13.922C27.5317 14.2146 27.5317 14.8 27.239 15.2098L24.9561 18.1951L27.239 21.239ZM16.5854 20.8878C15.0634 20.8878 13.8341 19.7171 13.8341 18.1951C13.8341 16.7317 15.0634 15.5024 16.5854 15.5024C18.1073 15.5024 19.3366 16.7317 19.3366 18.1951C19.278 19.7171 18.0488 20.8878 16.5854 20.8878ZM8.50732 20.8878C6.98537 20.8878 5.7561 19.7171 5.7561 18.1951C5.7561 16.7317 6.98537 15.5024 8.50732 15.5024C10.0293 15.5024 11.2585 16.7317 11.2585 18.1951C11.2585 19.7171 10.0293 20.8878 8.50732 20.8878ZM16.5854 13.7463C14.8878 13.7463 13.3659 14.6829 12.6049 16.0878C11.8439 14.6829 10.322 13.7463 8.56585 13.7463C7.5122 13.7463 6.57561 14.0976 5.81463 14.6244V10.878C5.81463 10.4098 5.40488 10 4.93659 10C4.40976 10 4 10.4098 4 10.878V18.2537C4.05854 20.7122 6.04878 22.6439 8.50732 22.6439C10.2634 22.6439 11.7854 21.6488 12.5463 20.2439C13.3073 21.6488 14.8293 22.6439 16.5268 22.6439C19.0439 22.6439 21.0927 20.6537 21.0927 18.1366C21.1512 15.7366 19.1024 13.7463 16.5854 13.7463Z"
				fill="#0071F7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7_563">
				<rect width="24" height="12.878" fill="white" transform="translate(4 10)" />
			</clipPath>
		</defs>
	</svg>
);
