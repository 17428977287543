import type { EntityATI } from '@atlassian/search-client';

import {
	DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
} from '../../entities';

/**
 * The set of possible values the universal type filter shows a user
 *
 * This is the superset. Only values that have results for a 1P/3P available to an end user will be shown
 *
 * Each connector maps nouns, subtypes into this list
 */
export const TypeFilterValueKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	Repository: 'repository',
	PullRequest: 'pull-request',
	Branch: 'branch',
	Commit: 'commit',
	WebPage: 'web-page',
	Conversation: 'conversation',
	Message: 'message',
	Video: 'video',
	Design: 'design',
	Page: 'page',
	Blogpost: 'blogpost',
	Attachment: 'attachment',
	Space: 'space',
	Whiteboard: 'whiteboard',
	Comment: 'comment',
	Database: 'database',
	Embed: 'embed',
	Folder: 'folder',
	Calendar: 'calendar',
	SpaceCalendar: 'space-calendar',
	Question: 'question',
	Answer: 'answer',
	Issue: 'issue',
	Component: 'component',
	Card: 'card',
	Project: 'project',
	Goal: 'goal',
	Tag: 'tag',
	WorkItem: 'work-item',
	Incident: 'incident',
	FocusArea: 'focus-area',
	Dashboard: 'dashboard',
	Invoice: 'invoice',
	Deal: 'deal',
} as const;
export type TypeFilterValueKey = (typeof TypeFilterValueKey)[keyof typeof TypeFilterValueKey];
export const allTypeFilterValueKeys = Object.values(TypeFilterValueKey);

/**
 * Subtypes used to seperate nouns
 *
 * These are statically mapped to a noun below
 */
export const NounSubtypeKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	File: 'file',
	Database: 'database',
	WebPage: 'web-page',
	Page: 'page',
	Blogpost: 'blogpost',
} as const;
export type NounSubtypeKey = (typeof NounSubtypeKey)[keyof typeof NounSubtypeKey];

/**
 * A mapping between UI values for the type filter
 * - TypeFilterKey e.g. 'Spreadsheet'
 *
 * and TWG entities to request from Aggregator
 * - entities3P e.g. ['ati:cloud:graph:document'],
 * - subTypes3P e.g. ['spreadsheet']
 */
export type TypeFilterTWGNounMapping = Map<
	TypeFilterValueKey,
	{ entities3P: EntityATI[]; subTypes3P: NounSubtypeKey[] }
>;

export const typeFilterTWGNounMapping: TypeFilterTWGNounMapping = new Map([
	[
		TypeFilterValueKey.Document,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Document],
		},
	],
	[
		TypeFilterValueKey.Spreadsheet,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Spreadsheet],
		},
	],
	[
		TypeFilterValueKey.Presentation,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Presentation],
		},
	],
	[
		TypeFilterValueKey.Repository,
		{
			entities3P: ['ati:cloud:graph:repository'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.PullRequest,
		{
			entities3P: ['ati:cloud:graph:pull-request'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Branch,
		{
			entities3P: ['ati:cloud:graph:branch'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Commit,
		{
			entities3P: ['ati:cloud:graph:commit'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.WebPage,
		{
			entities3P: ['ati:cloud:graph:document'],
			subTypes3P: [NounSubtypeKey.WebPage],
		},
	],
	[
		TypeFilterValueKey.Conversation,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Message,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Video,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Design,
		{
			// We don't have strong handling for smartLink connectors yet
			// See https://hello.atlassian.net/wiki/x/E4P-DwE
			// For now we'll add their entity sets as required
			entities3P: [
				...DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
				...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
			],
			subTypes3P: [],
			// While some Design nouns are subtyped with file, smartLink results aren't so for now we're ignoring this
			// subTypes3P: [NounSubtypeKey.File],
		},
	],
	[
		TypeFilterValueKey.WorkItem,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Page,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Page],
		},
	],
	[
		TypeFilterValueKey.Blogpost,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Blogpost],
		},
	],
	[
		TypeFilterValueKey.Space,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Calendar,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
			subTypes3P: [],
		},
	],
]);
