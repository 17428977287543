import React from 'react';

export const AtlasSearchResultIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.555 30.1532C8.07887 30.1532 2 24.0743 2 16.5981C2 9.12199 8.07887 3.04312 15.555 3.04312V5.2012C9.26799 5.2012 4.15883 10.3104 4.15883 16.5974C4.15883 22.8844 9.26799 27.9936 15.555 27.9936V30.1517V30.1532Z"
			fill="#1868DB"
		/>
		<path
			d="M15.5547 30.1532V27.9951C21.8417 27.9951 26.9509 22.8859 26.9509 16.5989H29.109C29.109 24.075 23.0301 30.1539 15.554 30.1539L15.5547 30.1532Z"
			fill="#1868DB"
		/>
		<path
			d="M29.1099 16.5981H26.9519C26.9519 10.3111 21.8427 5.20194 15.5557 5.20194V3.04385C23.0318 3.04385 29.1107 9.12272 29.1107 16.5989L29.1099 16.5981Z"
			fill="#1868DB"
		/>
		<path
			d="M21.3291 20.7481C19.8899 20.4665 18.7539 19.9558 16.8662 19.1007C16.345 18.8715 15.7714 18.611 15.1147 18.3189C14.7186 18.1415 14.3539 17.985 13.9885 17.8292C11.1422 16.5674 9.57795 15.8793 7.65948 15.8793C6.31461 15.8793 5.30297 16.244 4.73986 16.9321C4.35422 17.3911 4.19772 17.985 4.31229 18.5585L2.19539 18.9652C1.96625 17.7558 2.27851 16.515 3.0715 15.5558C3.75966 14.7216 5.11502 13.7205 7.65948 13.7205C10.037 13.7205 11.9554 14.5756 14.8646 15.8583C15.2188 16.0148 15.5842 16.1818 15.9803 16.348C16.6475 16.6401 17.2316 16.9007 17.7527 17.1403C19.5566 17.9535 20.5473 18.402 21.7462 18.6312L21.3291 20.7481V20.7481Z"
			fill="#1868DB"
		/>
		<path
			d="M23.437 20.9562C22.7174 20.9562 22.0292 20.8933 21.3306 20.7481L21.7477 18.6312C22.3108 18.7457 22.8634 18.7982 23.437 18.7982C24.7714 18.7982 25.783 18.4642 26.3671 17.8284C26.9512 17.1927 26.9721 16.4207 26.9407 16.0141L29.0883 15.8261C29.2028 17.1395 28.8067 18.3706 27.9621 19.2879C27.2634 20.0487 25.9185 20.9562 23.437 20.9562Z"
			fill="#1868DB"
		/>
		<path
			d="M15.555 30.1531V27.9951C18.652 27.9951 20.4971 24.8358 20.4971 19.5492C20.4971 14.2626 18.6206 9.83109 14.7732 5.73358L16.3479 4.25317C20.5915 8.76777 22.656 13.7728 22.656 19.5492C22.656 26.0871 19.9348 30.1531 15.555 30.1531V30.1531Z"
			fill="#1868DB"
		/>
		<path
			d="M15.5535 30.1532C10.8929 30.1532 8.22339 26.3582 8.22339 19.7372C8.22339 12.0109 11.602 7.69399 14.7613 4.26367L16.3465 5.72311C12.6661 9.72702 10.3822 13.4599 10.3822 19.7364C10.3822 26.5656 13.1978 27.9943 15.5543 27.9943V30.1524L15.5535 30.1532Z"
			fill="#1868DB"
		/>
		<path
			d="M9.77765 17.7244C11.1712 17.7244 12.3012 16.5944 12.3012 15.2009C12.3012 13.8073 11.1712 12.6774 9.77765 12.6774C8.38411 12.6774 7.25415 13.8073 7.25415 15.2009C7.25415 16.5944 8.38411 17.7244 9.77765 17.7244Z"
			fill="#1868DB"
		/>
		<path
			d="M21.4429 22.0727C22.8365 22.0727 23.9664 20.9427 23.9664 19.5492C23.9664 18.1556 22.8365 17.0257 21.4429 17.0257C20.0494 17.0257 18.9194 18.1556 18.9194 19.5492C18.9194 20.9427 20.0494 22.0727 21.4429 22.0727V22.0727Z"
			fill="#1868DB"
		/>
		<path
			d="M15.5526 7.047C16.9461 7.047 18.0761 5.91704 18.0761 4.5235C18.0761 3.12996 16.9461 2 15.5526 2C14.159 2 13.0291 3.12996 13.0291 4.5235C13.0291 5.91704 14.159 7.047 15.5526 7.047V7.047Z"
			fill="#1868DB"
		/>
	</svg>
);
