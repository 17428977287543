import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

const DEFAULT_GRAPHQL_ENDPOINT = '/gateway/api/graphql';

export async function fetchAgg<Variables, Data>({
	graphQuery,
	variables,
	operationName,
	aggAbsoluteUrl: url = DEFAULT_GRAPHQL_ENDPOINT,
	headers,
}: AggRequest<Variables>) {
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
			body: JSON.stringify({
				operationName,
				query: graphQuery,
				variables,
			}),
		},
		queryParams: {
			...(operationName && { q: operationName }),
		},
	};
	return utils.requestService<AggResponse<Data>>({ url }, options);
}

type Maybe<T> = T | null;

export type AggError = {
	message?: Maybe<string>;
	locations?: Maybe<
		Array<{
			line: number;
			column: number;
		}>
	>;
	extensions?: Maybe<{
		errorSource?: Maybe<string>;
		classification?: Maybe<string>;
	}>;
};

export type AggRequest<Variables> = {
	graphQuery: string;
	variables: Variables;
	operationName?: string;
	aggAbsoluteUrl?: string;
	headers?: Record<string, string>;
};

export interface AggResponse<Data> {
	data?: Maybe<Data>;
	errors?: Maybe<AggError[]>;
	extensions?: Maybe<AggExtensions>;
}

export interface AggResults<Edge> {
	edges: Edge[];
	abTest?: Maybe<AggAbTest>;
	totalCount: number;
}

export interface AggEdge<Node> {
	node: Node;
}

export interface AggAbTest {
	abTestId?: Maybe<string>;
	controlId?: Maybe<string>;
	experimentId?: Maybe<string>;
}

export interface Latency {
	started?: Maybe<string>;
	duration?: Maybe<number>;
	service?: Maybe<string>;
	targetRegion?: Maybe<string>;
}

// Marking all optional for safety
export interface AggExtensions {
	gateway?: {
		request_id?: Maybe<string>;
		crossRegion?: Maybe<boolean>;
		edgeCrossRegion?: Maybe<boolean>;
		duration?: Maybe<number>;
		deprecatedFieldsUsed?: Maybe<any[]>;
		micros_env?: Maybe<string>;
		invokedServices?: Maybe<string[]>;
		schema?: Maybe<{
			schemaName?: Maybe<string>;
			commitHash?: Maybe<string>;
			schemaHash?: Maybe<string>;
			snapshot?: Maybe<string>;
			buildNumber?: Maybe<string>;
			buildTimestamp?: Maybe<string>;
		}>;
		httpCallLatency?: Maybe<Latency[]>;
		httpLatencyPerService?: Maybe<{
			search?: Maybe<number>;
			UppClient?: Maybe<number>;
		}>;
		interestingLatencie?: Maybe<Latency[]>;
	};
}
