import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AsanaFilterIcon } from './icon';
import { AsanaIcon } from './search-result-icon';

export const asanaConfig: ThirdPartyConfig = {
	displayName: 'Asana',
	id: 'asana',
	icon: RovoLogoFromGlyph(AsanaFilterIcon, 'Asana'),
	resourceType: 'asana-connector',
	searchResultIcon: RovoLogoFromGlyph(AsanaIcon, 'Asana'),
	integrationAri: 'ari:cloud:platform::integration/asana', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future,
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_asana'),
};
