import React from 'react';

import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const GithubSearchResultIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0001 4C9.3735 4 4 9.50847 4 16.3038C4 21.74 7.43839 26.3519 12.2064 27.9789C12.8061 28.0927 13.0263 27.7119 13.0263 27.387C13.0263 27.0936 13.0152 26.1244 13.01 25.0963C9.67157 25.8405 8.96712 23.6446 8.96712 23.6446C8.42125 22.2224 7.63472 21.8443 7.63472 21.8443C6.54595 21.0806 7.71679 21.0963 7.71679 21.0963C8.92181 21.1831 9.55632 22.3642 9.55632 22.3642C10.6266 24.2452 12.3636 23.7014 13.0484 23.387C13.1561 22.5918 13.4671 22.049 13.8102 21.7418C11.1449 21.4307 8.34295 20.3757 8.34295 15.6612C8.34295 14.3179 8.81172 13.2203 9.57937 12.3587C9.45477 12.0488 9.04402 10.7974 9.69561 9.10261C9.69561 9.10261 10.7033 8.77193 12.9965 10.3638C13.9537 10.0912 14.9803 9.95447 16.0001 9.94979C17.0199 9.95447 18.0473 10.0912 19.0063 10.3638C21.2967 8.77193 22.303 9.10261 22.303 9.10261C22.9562 10.7974 22.5452 12.0488 22.4206 12.3587C23.1901 13.2203 23.6557 14.3179 23.6557 15.6612C23.6557 20.3869 20.8484 21.4274 18.1762 21.732C18.6067 22.1138 18.9902 22.8626 18.9902 24.0105C18.9902 25.6568 18.9763 26.9817 18.9763 27.387C18.9763 27.7144 19.1923 28.098 19.8006 27.9772C24.566 26.3485 28 21.7381 28 16.3038C28 9.50847 22.6273 4 16.0001 4Z"
			fill={token('color.icon', N800)}
		/>
	</svg>
);
