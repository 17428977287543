import React from 'react';
export const AdobeSignIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.24 4H23.76C26.08 4 28 5.92 28 8.24V23.12C28 25.48 26.08 27.36 23.76 27.36H8.24C5.92 27.4 4 25.48 4 23.16V8.24C4 5.92 5.92 4 8.24 4Z"
			fill="#584CCC"
		/>
		<path
			d="M23.28 17.56C22.8 17.04 21.88 16.8 20.56 16.8C19.84 16.8 19.08 16.88 18.36 17C17.88 16.56 17.48 16.04 17.08 15.52C16.8 15.12 16.52 14.72 16.28 14.28C16.68 13.16 16.92 11.96 17 10.76C17 9.68002 16.56 8.52002 15.36 8.52002C14.96 8.52002 14.56 8.76002 14.36 9.12002C13.84 10 14.04 11.8 14.88 13.68C14.6 14.52 14.28 15.36 13.92 16.28C13.6 17.08 13.24 17.84 12.8 18.56C11.56 19.04 8.96004 20.24 8.72004 21.56C8.64004 21.96 8.76004 22.36 9.08004 22.6C9.36004 22.84 9.76004 23 10.16 22.96C11.72 22.96 13.28 20.8 14.36 18.92C14.96 18.72 15.56 18.52 16.2 18.36C16.88 18.2 17.52 18.04 18.12 17.92C19.8 19.36 21.28 19.6 22 19.6C23 19.6 23.4 19.16 23.52 18.8C23.68 18.36 23.6 17.88 23.28 17.56ZM22.2 18.32C22.16 18.6 21.84 18.8 21.44 18.8C21.32 18.8 21.2 18.8 21.08 18.76C20.28 18.56 19.52 18.16 18.88 17.64C19.4 17.56 19.96 17.52 20.48 17.52C20.84 17.52 21.2 17.56 21.56 17.6C21.92 17.68 22.32 17.84 22.2 18.32ZM15.04 9.48002C15.12 9.36002 15.24 9.28002 15.4 9.24002C15.8 9.24002 15.88 9.68002 15.88 10.08C15.84 11 15.68 11.88 15.4 12.76C14.72 11.04 14.8 9.88002 15.04 9.48002ZM17.28 17.16C16.84 17.24 16.4 17.36 15.96 17.48C15.64 17.56 15.32 17.68 14.96 17.76C15.12 17.4 15.28 17.04 15.44 16.72C15.64 16.28 15.8 15.84 15.96 15.4C16.12 15.64 16.24 15.84 16.4 16.04C16.68 16.44 17 16.8 17.28 17.16ZM12.44 19.48C11.44 21.08 10.44 22.12 9.88004 22.12C9.80004 22.12 9.68005 22.08 9.64004 22.04C9.52004 21.96 9.48004 21.8 9.52004 21.68C9.60004 21.08 10.76 20.24 12.44 19.48Z"
			fill="white"
		/>
	</svg>
);
