const DEFAULT_EXPIRATION = 1000 * 60 * 15; // 15 mins in milliseconds

export interface ICache {
	/**
	 * In ms determine the duration for which the entry should be valid.
	 */
	expiration?: number;
}

class Cache<T> {
	private map: Record<string, Entry<T>>;
	private expiration: number;

	constructor(args: ICache = {}) {
		const { expiration = DEFAULT_EXPIRATION } = args;
		this.map = {};
		this.expiration = expiration;
	}

	public get(key: string, returnExpired: boolean = false) {
		const entry = this.map[key];

		if (entry) {
			if (entry.isExpired()) {
				delete this.map[key];
				return returnExpired ? entry.getResult() : undefined;
			} else {
				return entry.getResult();
			}
		}

		return;
	}

	public set(key: string, result: Promise<T>) {
		const entry = new Entry({
			result,
			expirationTime: Date.now() + this.expiration,
			accessCount: 0,
		});

		this.map[key] = entry;
	}

	public has(key: string) {
		return key in this.map;
	}

	public clear(key?: string) {
		if (key) {
			delete this.map[key];
		} else {
			this.map = {};
		}
	}

	public async serialize() {
		const serialized: Record<string, IEntry<T>> = {};
		const resolvers = Object.entries(this.map).map(([key, entry]) =>
			(async () => {
				serialized[key] = {
					result: await entry.getResult(),
					expirationTime: entry.getExpirationTime(),
					accessCount: entry.getAccessCount(),
				};
			})(),
		);
		await Promise.all(resolvers);
		return JSON.stringify(serialized);
	}

	public deserialize(persisted: string) {
		try {
			const object = JSON.parse(persisted);

			for (const key in object) {
				const { result, expirationTime, accessCount }: IEntry<T> = object[key];

				this.map[key] = new Entry({
					result: Promise.resolve(result),
					expirationTime,
					accessCount,
				});
			}
		} catch (e) {
			// TODO: Log error via sentry
		}
	}
}

interface IEntry<T> {
	result: T;
	expirationTime: number;
	accessCount: number;
}

class Entry<T> {
	private result: Promise<T>;
	private expirationTime: number;
	private accessCount: number;

	constructor({ result, expirationTime, accessCount }: IEntry<Promise<T>>) {
		this.result = result;
		this.expirationTime = expirationTime;
		this.accessCount = accessCount;
	}

	public getResult() {
		this.accessCount++;
		return this.result;
	}

	public isExpired() {
		return this.expirationTime < Date.now();
	}

	public getExpirationTime() {
		return this.expirationTime;
	}

	public getAccessCount() {
		return this.accessCount;
	}
}

export const getInstance = <T>(args?: ICache) => {
	return new Cache<T>(args);
};
