import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OutlookMailIcon } from './icon';
import { OutlookMailSearchResultIcon } from './search-result-icon';

export const outlookMail: ThirdPartyConfig = {
	displayName: 'Outlook Mail',
	id: 'outlook-mail',
	resourceType: 'outlook-mail-connector',
	icon: RovoLogoFromGlyph(OutlookMailIcon, 'Outlook Mail'),
	searchResultIcon: RovoLogoFromGlyph(OutlookMailSearchResultIcon, 'Outlook Mail'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	requiresOauth: true,
	typeFilterValues: [TypeFilterValueKey.Message],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_outlook_mail'),
	isFederated: true,
};
