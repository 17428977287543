import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { ClickUpFilterIcon } from './icon';
import { ClickUpIcon } from './search-result-icon';

export const clickUpConfig: ThirdPartyConfig = {
	displayName: 'ClickUp',
	id: 'clickup',
	resourceType: 'smartlinks-connector-clickup',
	icon: RovoLogoFromGlyph(ClickUpFilterIcon, 'ClickUp'),
	searchResultIcon: RovoLogoFromGlyph(ClickUpIcon, 'ClickUp'),
	integrationAri: 'ari:cloud:platform::integration/clickup',
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => false,
};
