import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OutlookCalendarIcon } from './icon';

export const outlookCalendarConfig: ThirdPartyConfig = {
	displayName: 'Outlook Calendar',
	id: 'outlook-calendar',
	resourceType: 'outlook-calendar-connector',
	icon: RovoLogoFromGlyph(OutlookCalendarIcon, 'Outlook Calendar'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterValues: [TypeFilterValueKey.Calendar],
	requiresOauth: true,
	isEnabled: () => fg('enable_3p_search_outlook_calendar'),
};
