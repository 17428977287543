import React from 'react';

export const PowerBIIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24.1048 4H17.7429C17.1959 4 16.7524 4.44345 16.7524 4.99048V27.0095C16.7524 27.5566 17.1959 28 17.7429 28H24.1048C24.6518 28 25.0953 27.5566 25.0953 27.0095V4.99048C25.0953 4.44345 24.6518 4 24.1048 4Z"
			fill="url(#paint0_linear_384_137)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.1809 27.0095V28H12.8285C12.2814 28 11.838 27.5566 11.838 27.0095V10.9714C11.838 10.4244 12.2814 9.98096 12.8285 9.98096H19.1904C19.7374 9.98096 20.1809 10.4244 20.1809 10.9714V27.0095Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.1809 27.0095V28H12.8285C12.2814 28 11.838 27.5566 11.838 27.0095V10.9714C11.838 10.4244 12.2814 9.98096 12.8285 9.98096H19.1904C19.7374 9.98096 20.1809 10.4244 20.1809 10.9714V27.0095Z"
			fill="url(#paint1_linear_384_137)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.3429 27.0095V28H7.99048C7.44343 28 7 27.5566 7 27.0095V16.9905C7 16.4434 7.44343 16 7.99048 16H14.3524C14.8994 16 15.3429 16.4434 15.3429 16.9905V27.0095Z"
			fill="url(#paint2_linear_384_137)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_384_137"
				x1="20.8843"
				y1="3.96952"
				x2="20.8843"
				y2="27.9695"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#EBBB14" />
				<stop offset="1" stopColor="#B25400" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_384_137"
				x1="15.9698"
				y1="9.90667"
				x2="15.9698"
				y2="27.9257"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9E583" />
				<stop offset="1" stopColor="#DE9800" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_384_137"
				x1="11.1318"
				y1="15.9695"
				x2="11.1318"
				y2="27.9695"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9E68B" />
				<stop offset="1" stopColor="#F3CD32" />
			</linearGradient>
		</defs>
	</svg>
);
