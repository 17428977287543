import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { DocusignFilterIcon } from './icon';
import { DocusignIcon } from './search-result-icon';

export const docusignConfig: ThirdPartyConfig = {
	displayName: 'Docusign',
	id: 'docusign',
	resourceType: 'docusign-connector',
	icon: RovoLogoFromGlyph(DocusignFilterIcon, 'Docusign'),
	searchResultIcon: RovoLogoFromGlyph(DocusignIcon, 'Docusign'),
	integrationAri: 'ari:cloud:platform::integration/docusign',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
