import React from 'react';

export const MuralIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.0133 21.265C17.0138 18.0207 17.0266 14.7762 17.0111 11.532C16.9984 8.91786 18.8396 6.66117 21.3367 6.12412C21.7201 6.04165 22.1067 5.98833 22.5005 6.00685C22.5633 6.10039 22.5551 6.20726 22.5554 6.31165C22.5565 7.2563 22.5567 8.20118 22.5549 9.14583C22.5547 9.25654 22.5707 9.3729 22.4917 9.46915C21.8274 9.49174 21.2867 9.75925 20.885 10.2911C20.6338 10.6239 20.4991 11.0015 20.4894 11.4206C20.4874 11.5107 20.4864 11.6009 20.4864 11.6913C20.4864 14.7963 20.4864 17.9014 20.486 21.0065C20.486 21.0964 20.4797 21.1864 20.4765 21.2765C20.409 21.3536 20.3156 21.3515 20.2266 21.352C19.244 21.3558 18.2612 21.3558 17.2786 21.352C17.1834 21.3515 17.0797 21.3608 17.0135 21.265H17.0133Z"
			fill="#00843F"
		/>
		<path
			d="M27.9937 11.5058C27.9955 11.5885 27.9986 11.6712 27.9986 11.7539C27.9989 15.9921 27.9991 20.23 27.9982 24.4682C27.9982 24.7886 28.0393 24.7452 27.7262 24.7456C26.7476 24.7474 25.7691 24.7463 24.7903 24.7463C24.7377 24.7463 24.6848 24.744 24.6321 24.7463C24.5524 24.7497 24.5259 24.7072 24.5293 24.6347C24.5307 24.6046 24.5293 24.5746 24.5293 24.5443C24.5298 20.2083 24.53 15.8725 24.5305 11.5365C24.5305 11.5291 24.5311 11.5214 24.5314 11.5139C24.5854 11.4073 24.6877 11.4057 24.7844 11.4053C25.7666 11.4001 26.7487 11.4003 27.7309 11.4046C27.8303 11.405 27.9263 11.419 27.9934 11.5056L27.9937 11.5058Z"
			fill="black"
		/>
		<path
			d="M7.47371 11.4879C7.4771 11.5703 7.4832 11.6528 7.4832 11.7353C7.48365 15.9854 7.48388 20.2355 7.4832 24.4857C7.4832 24.7939 7.51754 24.7512 7.22382 24.7516C6.23082 24.7532 5.23782 24.7532 4.24504 24.7505C4.16799 24.7505 4.07762 24.7878 4.01526 24.7039C4.04124 24.6228 4.02294 24.5399 4.02294 24.4581C4.0243 20.2143 4.02407 15.9705 4.02407 11.7269C4.02407 11.4255 4.04011 11.4034 4.3379 11.402C5.28594 11.3979 6.23398 11.3993 7.18202 11.4018C7.28596 11.402 7.39599 11.3939 7.47394 11.4879H7.47371Z"
			fill="black"
		/>
		<path
			d="M14.9941 24.7276C14.9227 24.7622 14.8461 24.7486 14.7718 24.7489C13.786 24.7511 12.8 24.7523 11.8142 24.7534C11.7765 24.7534 11.739 24.7545 11.7013 24.7532C11.5979 24.7494 11.5445 24.6925 11.5411 24.5823C11.5404 24.5598 11.5411 24.5372 11.5411 24.5146C11.5411 20.24 11.5411 15.9653 11.5413 11.6907C11.5413 11.6232 11.5463 11.5556 11.549 11.4878C11.6267 11.395 11.7358 11.4017 11.8398 11.4013C12.7919 11.3988 13.744 11.3988 14.6961 11.4013C14.8002 11.4015 14.9089 11.3959 14.9869 11.488C14.9898 11.5856 14.9955 11.6832 14.9955 11.7809C14.9959 14.8487 14.9957 17.9165 14.9964 20.984C14.9964 21.0814 14.9848 21.1797 15.011 21.2757C15.0553 21.3668 15.0551 21.4646 15.0551 21.5624C15.0553 22.5166 15.0562 23.4707 15.0542 24.4246C15.0542 24.5283 15.0671 24.637 14.9941 24.7272V24.7276Z"
			fill="#5887FF"
		/>
		<path
			d="M14.9871 11.4884C13.8411 11.4884 12.6951 11.4884 11.5489 11.4882C11.5123 11.1737 11.4601 10.8646 11.3122 10.5777C10.9129 9.80383 10.0609 9.3628 9.20685 9.49362C8.34377 9.62579 7.65827 10.3077 7.51773 11.1753C7.50101 11.279 7.48836 11.3834 7.4739 11.4875C6.40453 11.4875 5.33516 11.4871 4.26579 11.4869C4.23574 11.4869 4.20524 11.4893 4.17541 11.4866C4.07193 11.4774 4.01838 11.5165 4.02629 11.6272C4.03058 11.687 4.02674 11.7476 4.02674 11.8079C4.02674 16.0325 4.02674 20.2574 4.02629 24.482C4.02629 24.5565 4.01929 24.6311 4.01522 24.7048C3.98901 24.6462 4.0046 24.5855 4.0046 24.5267C4.00347 20.2517 4.00302 15.9765 4.00415 11.7015C4.00415 10.9279 4.10311 10.1685 4.41084 9.45227C5.2258 7.55641 6.65012 6.3917 8.68606 6.0686C11.5453 5.61469 14.2132 7.45768 14.8705 10.281C14.9627 10.6771 15.0194 11.0793 14.9868 11.4884H14.9871Z"
			fill="#FF4B4B"
		/>
		<path
			d="M27.9938 11.5059C27.724 11.503 27.4542 11.4978 27.1844 11.4973C26.3819 11.4962 25.5796 11.4967 24.7771 11.4973C24.695 11.4973 24.6119 11.4887 24.5317 11.5145C24.5177 11.2901 24.4955 11.069 24.4257 10.8518C24.1783 10.083 23.4293 9.49619 22.6028 9.48173C22.5656 9.48105 22.5285 9.47382 22.4915 9.46953C22.4955 9.42479 22.503 9.38006 22.503 9.33532C22.5025 8.22573 22.5014 7.11637 22.5005 6.00701C25.5272 5.96928 28.0385 8.50341 27.9938 11.5061V11.5059Z"
			fill="#FC83FF"
		/>
		<path
			d="M14.9941 24.7279C14.9941 23.6594 14.9941 22.5909 14.9947 21.5227C14.9947 21.4404 14.9823 21.3571 15.011 21.2764C15.6784 21.2726 16.3459 21.2687 17.0133 21.2649C17.0878 21.269 17.1626 21.2764 17.2372 21.2764C18.317 21.2771 19.3965 21.2766 20.4763 21.2764C20.4796 21.3512 20.4862 21.4262 20.4862 21.501C20.4869 22.4929 20.4866 23.485 20.4866 24.4768C20.4866 24.5295 20.4848 24.5821 20.4862 24.6346C20.488 24.7082 20.4557 24.7468 20.38 24.7459C20.3274 24.7455 20.2747 24.7471 20.2223 24.7471C18.5614 24.7471 16.9008 24.7471 15.2399 24.7468C15.1576 24.7468 15.0743 24.7575 14.9943 24.7276L14.9941 24.7279Z"
			fill="#FFAA00"
		/>
	</svg>
);
