import React from 'react';

export const AdobeXDIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.35897 4H24.2564C26.6667 4 28.6154 5.94872 28.6154 8.35897V23.641C28.6154 26.0513 26.6667 28 24.2564 28H8.35897C5.94872 28 4 26.0513 4 23.641V8.35897C4 5.94872 5.94872 4 8.35897 4Z"
			fill="#470137"
		/>
		<path
			d="M16.9436 10.3077L13.8666 15.3846L17.1487 20.7692C17.1692 20.8102 17.1795 20.8512 17.1692 20.8923C17.1589 20.9333 17.1179 20.9025 17.0564 20.9128H14.7077C14.5436 20.9128 14.4307 20.9025 14.3589 20.8C14.1436 20.3692 13.9179 19.9487 13.7025 19.5179C13.4872 19.0974 13.2513 18.6666 13.0051 18.2256C12.7589 17.7846 12.5128 17.3436 12.2666 16.8923H12.2461C12.0307 17.3333 11.7948 17.7743 11.5589 18.2153C11.3231 18.6564 11.0872 19.0974 10.8615 19.5282C10.6256 19.9589 10.3897 20.4 10.1538 20.8205C10.1128 20.923 10.0307 20.9333 9.91792 20.9333H7.66151C7.62049 20.9333 7.58972 20.9538 7.58972 20.9025C7.57946 20.8615 7.58972 20.8205 7.61023 20.7897L10.8 15.5589L7.69228 10.2974C7.66151 10.2564 7.65126 10.2153 7.67177 10.1948C7.69228 10.1641 7.73331 10.1538 7.77433 10.1538H10.1025C10.1538 10.1538 10.2051 10.1641 10.2461 10.1743C10.2872 10.1948 10.3179 10.2256 10.3487 10.2666C10.5436 10.7077 10.7692 11.1487 11.0051 11.5897C11.2513 12.0307 11.4872 12.4615 11.7436 12.8923C11.9897 13.323 12.2154 13.7538 12.4307 14.1948H12.4513C12.6666 13.7436 12.8923 13.3025 13.1179 12.8718C13.3436 12.441 13.5795 12.0102 13.8154 11.5794C14.0513 11.1487 14.2769 10.7077 14.5025 10.2871C14.5128 10.2461 14.5333 10.2051 14.5641 10.1846C14.6051 10.1641 14.6461 10.1538 14.6974 10.1641H16.8615C16.9128 10.1538 16.9641 10.1846 16.9743 10.2359C16.9846 10.2461 16.9641 10.2871 16.9436 10.3077Z"
			fill="#FF61F6"
		/>
		<path
			d="M21.6821 21.1283C20.9231 21.1385 20.1642 20.9847 19.477 20.6667C18.8308 20.3693 18.2975 19.877 17.9283 19.2719C17.5488 18.6462 17.3642 17.8667 17.3642 16.9334C17.3539 16.1744 17.5488 15.4257 17.9283 14.7693C18.318 14.1026 18.8821 13.5488 19.559 13.1796C20.277 12.7796 21.1385 12.5847 22.1539 12.5847C22.2052 12.5847 22.277 12.5847 22.3693 12.5949C22.4616 12.6052 22.5642 12.6052 22.6872 12.6154V9.37442C22.6872 9.30263 22.718 9.2616 22.7898 9.2616H24.8718C24.9231 9.25135 24.9642 9.29237 24.9744 9.3334C24.9744 9.34366 24.9744 9.35391 24.9744 9.35391V19.118C24.9744 19.3026 24.9847 19.5078 24.9949 19.7334C25.0154 19.9488 25.0257 20.1539 25.0359 20.3283C25.0359 20.4001 25.0052 20.4616 24.9334 20.4924C24.4001 20.718 23.8359 20.8821 23.2616 20.9847C22.7385 21.077 22.2154 21.1283 21.6821 21.1283ZM22.6872 19.077V14.5642C22.5949 14.5437 22.5026 14.5231 22.4103 14.5129C22.2975 14.5026 22.1847 14.4924 22.0718 14.4924C21.6718 14.4924 21.2718 14.5744 20.9129 14.759C20.5642 14.9334 20.2667 15.1898 20.0411 15.518C19.8154 15.8462 19.7026 16.2872 19.7026 16.8206C19.6924 17.1796 19.7539 17.5385 19.877 17.877C19.9795 18.1539 20.1334 18.4001 20.3385 18.6052C20.5334 18.7898 20.7693 18.9334 21.0359 19.0154C21.3129 19.1078 21.6001 19.1488 21.8872 19.1488C22.0411 19.1488 22.1847 19.1385 22.318 19.1283C22.4513 19.1385 22.5642 19.118 22.6872 19.077Z"
			fill="#FF61F6"
		/>
	</svg>
);
