import React from 'react';

export const BoxIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.8413 15.6285C21.066 15.9431 21.0211 16.3476 20.7514 16.5723C20.4368 16.797 19.9874 16.752 19.7627 16.4824L18.1898 14.4601L16.6619 16.4375C16.4371 16.752 15.9877 16.752 15.6732 16.5273C15.3586 16.3026 15.3136 15.8982 15.5383 15.5836L17.336 13.2467L15.5383 10.9098C15.3136 10.5952 15.4035 10.1458 15.6732 9.92112C15.9877 9.69642 16.4371 9.7863 16.6619 10.0559L18.1898 12.0783L19.7627 10.1458C19.9874 9.83124 20.3919 9.7863 20.7514 10.011C21.066 10.2357 21.066 10.6851 20.8413 10.9997L19.0886 13.2916L20.8413 15.6285ZM12.6622 15.3589C11.4937 15.3589 10.55 14.4601 10.55 13.2916C10.55 12.1681 11.4937 11.2244 12.6622 11.2244C13.8306 11.2244 14.7744 12.1681 14.7744 13.2916C14.7294 14.4601 13.7857 15.3589 12.6622 15.3589ZM6.4604 15.3589C5.29196 15.3589 4.34821 14.4601 4.34821 13.2916C4.34821 12.1681 5.29196 11.2244 6.4604 11.2244C7.62885 11.2244 8.5726 12.1681 8.5726 13.2916C8.5726 14.4601 7.62885 15.3589 6.4604 15.3589ZM12.6622 9.87618C11.3589 9.87618 10.1904 10.5952 9.60622 11.6738C9.022 10.5952 7.85355 9.87618 6.50534 9.87618C5.69642 9.87618 4.97737 10.1458 4.39315 10.5503V7.6741C4.39315 7.31458 4.07857 7 3.71904 7C3.31458 7 3 7.31458 3 7.6741V13.3366C3.04494 15.2241 4.57291 16.7071 6.4604 16.7071C7.80861 16.7071 8.97706 15.9431 9.56128 14.8646C10.1455 15.9431 11.314 16.7071 12.6172 16.7071C14.5497 16.7071 16.1226 15.1791 16.1226 13.2467C16.1675 11.4041 14.5946 9.87618 12.6622 9.87618Z"
			fill="#0071F7"
		/>
	</svg>
);
