import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { FreshserviceFilterIcon } from './icon';
import { FreshserviceIcon } from './search-result-icon';

export const freshserviceConfig: ThirdPartyConfig = {
	displayName: 'Freshservice',
	id: 'freshservice',
	icon: RovoLogoFromGlyph(FreshserviceFilterIcon, 'Freshservice'),
	resourceType: 'freshservice-connector',
	searchResultIcon: RovoLogoFromGlyph(FreshserviceIcon, 'Freshservice'),
	integrationAri: 'ari:cloud:platform::integration/freshservice', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future,
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => true,
};
