import React from 'react';

export const NotionFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.8801 5.63103L13.9736 5.03323C14.9678 4.94796 15.2232 5.00544 15.8483 5.4599L18.4319 7.27992C18.858 7.59294 18.9999 7.67822 18.9999 8.01902V18.0009C18.9999 18.6265 18.7727 18.9965 17.9774 19.053L8.57877 19.6223C7.98199 19.6505 7.69779 19.5652 7.38521 19.1669L5.48269 16.6929C5.14144 16.2376 5 15.8968 5 15.4983V6.62566C5 6.11416 5.22716 5.68764 5.8801 5.63103Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.9736 5.03338L5.8801 5.63118C5.22716 5.68764 5 6.11431 5 6.62566V15.4983C5 15.8966 5.14144 16.2374 5.48269 16.6929L7.38521 19.1668C7.69779 19.5651 7.98199 19.6505 8.57877 19.6221L17.9776 19.0531C18.7723 18.9965 19 18.6265 19 18.001V8.01917C19 7.69591 18.8723 7.60274 18.4964 7.32688L15.8483 5.4599C15.2233 5.00544 14.9678 4.94796 13.9736 5.03323V5.03338ZM8.7913 7.85579C8.02382 7.90742 7.84976 7.91912 7.41388 7.56471L6.30574 6.68329C6.19312 6.5692 6.24972 6.42688 6.53349 6.39851L14.314 5.82996C14.9674 5.77291 15.3076 6.00066 15.5631 6.19958L16.8975 7.16642C16.9546 7.19524 17.0965 7.36535 16.9258 7.36535L8.89076 7.84906L8.7913 7.85579ZM7.89657 17.9157V9.44193C7.89657 9.07187 8.01022 8.90117 8.35044 8.8725L17.5791 8.33218C17.8922 8.30395 18.0336 8.50288 18.0336 8.87236V17.2897C18.0336 17.6598 17.9766 17.9728 17.4656 18.001L8.63435 18.513C8.12343 18.5412 7.89671 18.3711 7.89671 17.9157H7.89657ZM16.6142 9.89624C16.6708 10.1522 16.6142 10.4082 16.3582 10.4374L15.9326 10.5218V16.7782C15.563 16.9771 15.2227 17.0908 14.9384 17.0908C14.4839 17.0908 14.3704 16.9485 14.0301 16.5222L11.2466 12.1429V16.3799L12.1271 16.5793C12.1271 16.5793 12.1271 17.0912 11.4167 17.0912L9.45814 17.2049C9.40109 17.0908 9.45814 16.8066 9.65662 16.75L10.1681 16.6081V11.006L9.45828 10.9485C9.40124 10.6925 9.54312 10.3229 9.94097 10.2942L12.0424 10.1528L14.9385 14.5887V10.6643L14.2003 10.5795C14.1433 10.266 14.3704 10.0383 14.6542 10.0105L16.6142 9.89624Z"
			fill="black"
		/>
	</svg>
);
