import React from 'react';

export const AzureDevopsFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19 7.622V16.134L15.5 19L10.075 17.025V18.983L7.004 14.97L15.955 15.67V8.005L19 7.622ZM16.016 8.05L10.994 5V7.001L6.382 8.356L5 10.13V14.159L6.978 15.032V9.869L16.016 8.05Z"
			fill="url(#paint0_linear_24_1001)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_24_1001"
				x1="12"
				y1="18.956"
				x2="12"
				y2="5.026"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0078D4" />
				<stop offset="0.16" stopColor="#1380DA" />
				<stop offset="0.53" stopColor="#3C91E5" />
				<stop offset="0.82" stopColor="#559CEC" />
				<stop offset="1" stopColor="#5EA0EF" />
			</linearGradient>
		</defs>
	</svg>
);
