import React from 'react';

export const WebexIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_647_99" maskUnits="userSpaceOnUse" x="4" y="7" width="24" height="18">
			<path d="M28 7H4V24.4691H28V7Z" fill="white" />
		</mask>
		<g mask="url(#mask0_647_99)">
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint0_linear_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint1_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint2_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint3_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint4_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint5_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint6_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint7_linear_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint8_radial_647_99)"
			/>
			<path
				d="M20.4883 7C18.7081 7 16.6475 7.60346 15.2838 10.1966C14.5 11.6872 14.103 13.3653 13.7358 15.0306C13.6148 15.5786 13.4901 16.1332 13.3574 16.6591V24.1936C14.031 24.003 14.7546 23.6363 15.449 22.9307C18.0564 20.2804 18.4937 13.3462 19.7979 12.0208C19.9456 11.8706 20.1054 11.7833 20.2777 11.7833C20.8025 11.7833 21.2083 12.4484 21.5968 13.2483C22.0288 14.1375 22.542 15.152 24.0561 15.152C24.7483 15.152 26.3454 14.6525 26.3454 12.6944C26.3454 11.5226 24.3918 7 20.4883 7Z"
				fill="url(#paint9_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint10_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint11_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint12_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint13_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71192 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint14_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="#316AFF"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint15_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint16_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint17_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint18_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint19_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint20_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint21_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint22_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint23_radial_647_99)"
			/>
			<path
				d="M25.6256 9.66849C25.4496 9.66849 25.2726 9.68509 25.0991 9.71933C25.9242 10.9091 26.3371 12.1556 26.3371 12.6942C26.3371 14.6524 24.7399 15.1518 24.0477 15.1518C23.5075 15.1518 23.0948 15.0227 22.7677 14.8161C22.7646 14.8284 22.7617 14.8405 22.7586 14.8528C22.6613 15.2414 22.5845 15.6667 22.4516 16.0807C22.196 16.8765 21.8513 17.7856 21.4832 18.4891C21.0864 19.2475 20.6935 19.6912 20.2254 19.6679C19.6928 19.6415 19.2611 18.9551 18.7245 17.0152C18.5514 16.3897 18.3912 15.7039 18.2427 15.0306C17.8744 13.3603 17.4222 11.6808 16.6491 10.1966C15.3864 7.7726 13.2325 7 11.5128 7C9.91805 7 8.72657 7.75504 7.79056 8.7064C7.53193 8.96929 7.24934 9.32352 6.96558 9.73976C7.78196 9.93638 8.5539 10.5509 8.67712 11.7155C8.70144 11.9376 8.87183 13.7012 9.4442 15.745C9.60842 15.2326 9.84788 14.4737 10.4042 13.2483C10.6768 12.6872 10.9229 12.3171 11.1449 12.0915C11.3485 11.8845 11.5318 11.7991 11.6969 11.7991C11.8749 11.7991 12.4828 11.962 13.0746 14.0387C13.8085 16.6139 14.3547 20.7122 16.4357 22.8882C17.3535 23.8481 18.6379 24.4691 20.4181 24.4691C21.98 24.4691 23.2822 23.7812 24.2107 22.8243C25.7373 21.2507 26.5739 18.649 26.8723 17.6864C27.7213 14.9474 27.9593 12.4703 27.9869 12.2082C28.1449 10.7107 26.8833 9.66849 25.6256 9.66849Z"
				fill="url(#paint24_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71199 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint25_linear_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71199 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint26_radial_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71199 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint27_linear_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71199 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint28_linear_647_99)"
			/>
			<path
				d="M11.4467 24.3999C7.00273 24.3999 5.49018 18.7797 5.15126 17.6864C4.30016 14.9409 4.04143 12.4678 4.01404 12.2082C3.85599 10.7107 5.05709 9.66849 6.37532 9.66849C7.37342 9.66849 8.53183 10.266 8.68532 11.7157C8.71199 11.9595 8.91486 14.0624 9.63272 16.3537C10.3819 18.7451 11.1766 19.6648 11.7489 19.6648C12.6731 19.6648 13.237 17.2909 13.7355 15.0306C14.1028 13.3653 14.4998 11.6872 15.2837 10.1966C16.6473 7.60346 18.7079 7 20.4881 7C24.3915 7 26.3452 11.5226 26.3452 12.6944C26.3452 14.6525 24.748 15.152 24.0558 15.152C22.5419 15.152 22.0286 14.1375 21.5966 13.2483C21.2081 12.4484 20.8023 11.7833 20.2776 11.7833C20.1052 11.7833 19.9454 11.8706 19.7976 12.0208C18.4935 13.3462 18.0563 20.2804 15.4488 22.9307C14.0032 24.3999 12.4304 24.3999 11.4467 24.3999Z"
				fill="url(#paint29_radial_647_99)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_647_99"
				x1="23.9136"
				y1="12.1052"
				x2="8.21479"
				y2="17.2536"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#5CEE64" />
				<stop offset="1" stopColor="#00BBFF" />
			</linearGradient>
			<radialGradient
				id="paint1_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(13.135 16.279) rotate(-120.86) scale(10.2844 8.6336)"
			>
				<stop stopColor="#007383" />
				<stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
				<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint2_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(19.8922 14.3654) rotate(-126.734) scale(8.38731 5.5109)"
			>
				<stop stopColor="#218970" />
				<stop offset="0.459527" stopColor="#267E6A" />
				<stop offset="1" stopColor="#026C51" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint3_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(12.5428 16.7649) rotate(-107.468) scale(6.831 3.55876)"
			>
				<stop stopColor="#178697" />
				<stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
				<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint4_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(15.5798 16.7285) rotate(-120.86) scale(17.3472 5.35099)"
			>
				<stop stopColor="#25342F" stopOpacity="0.3" />
				<stop offset="1" stopColor="#25342F" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint5_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(18.6742 14.5556) rotate(85.0979) scale(6.53344 3.70568)"
			>
				<stop stopColor="#056D4F" />
				<stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
				<stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
				<stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint6_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(18.9632 3.98931) rotate(57.0774) scale(7.68019 4.02825)"
			>
				<stop stopColor="#22B67B" />
				<stop offset="0.407867" stopColor="#24BE82" />
				<stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
			</radialGradient>
			<linearGradient
				id="paint7_linear_647_99"
				x1="-1.90504"
				y1="15.5482"
				x2="9.2203"
				y2="15.6396"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#09F8FF" />
				<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
			</linearGradient>
			<radialGradient
				id="paint8_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(0.485034 5.52745) rotate(49.6506) scale(10.0307 10.0057)"
			>
				<stop stopColor="#09F8FF" />
				<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint9_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(8.52009 0.477253) rotate(48.0118) scale(20.3443 20.3123)"
			>
				<stop stopColor="#00B8FF" />
				<stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint10_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(9.59708 15.7364) rotate(-107.468) scale(9.8062 6.79461)"
			>
				<stop stopColor="#00BCFF" stopOpacity="0.76" />
				<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint11_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(24.5052 12.012) rotate(-129.551) scale(6.93436 4.85474)"
			>
				<stop stopColor="#5FEC50" />
				<stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint12_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(23.5126 16.4784) rotate(-79.0944) scale(2.09936 1.45201)"
			>
				<stop stopColor="#28855B" />
				<stop offset="1" stopColor="#14795C" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint13_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(23.4115 16.3238) rotate(-77.8798) scale(1.73068 2.41868)"
			>
				<stop stopColor="#28855B" />
				<stop offset="1" stopColor="#14795C" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint14_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(25.4732 13.2059) rotate(-132.79) scale(5.74108 4.03317)"
			>
				<stop stopColor="#5FF05B" />
				<stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint15_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(23.6778 14.855) rotate(37.4559) scale(5.10572 7.09685)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint16_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(22.4077 15.4304) rotate(8.48611) scale(6.02427 8.99209)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint17_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(22.8965 16.1778) rotate(24.6631) scale(5.14602 9.16968)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint18_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(22.2922 16.4826) rotate(95.8028) scale(9.25087 5.06958)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
				<stop offset="1" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint19_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(23.6778 12.3984) rotate(93.6671) scale(11.1332 3.97316)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint20_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(11.0609 14.7132) rotate(84.8296) scale(5.90774 3.12986)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint21_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(11.0566 14.8722) rotate(87.2504) scale(6.16565 5.0903)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint22_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(12.4594 20.6657) rotate(-23.1725) scale(6.87583 13.4399)"
			>
				<stop stopColor="#123AA8" />
				<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint23_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(10.013 13.3647) rotate(-159.526) scale(4.55533 7.79995)"
			>
				<stop stopColor="#1A3DA0" />
				<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<radialGradient
				id="paint24_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(6.00312 14.9151) rotate(16.749) scale(8.68827 8.77078)"
			>
				<stop stopColor="#1E3882" />
				<stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
			</radialGradient>
			<linearGradient
				id="paint25_linear_647_99"
				x1="3.27747"
				y1="10.1095"
				x2="11.839"
				y2="15.5739"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.229329" stopColor="#1DC4FF" />
				<stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
			</linearGradient>
			<radialGradient
				id="paint26_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(0.48452 5.52705) rotate(49.6506) scale(10.0307 10.0057)"
			>
				<stop stopColor="#09F8FF" />
				<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
			</radialGradient>
			<linearGradient
				id="paint27_linear_647_99"
				x1="3.7507"
				y1="10.3886"
				x2="6.15218"
				y2="12.5657"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#26FBFF" />
				<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				id="paint28_linear_647_99"
				x1="3.11268"
				y1="12.055"
				x2="6.43432"
				y2="12.2919"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#26FBFF" />
				<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
			</linearGradient>
			<radialGradient
				id="paint29_radial_647_99"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(9.59679 15.7365) rotate(-107.468) scale(9.8062 6.79461)"
			>
				<stop stopColor="#00BCFF" stopOpacity="0.76" />
				<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
			</radialGradient>
		</defs>
	</svg>
);
