import React from 'react';

export const StripeIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M4 4H28V28H4V4Z" fill="#635BFF" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.0639 13.3299C15.0639 12.7659 15.5259 12.5439 16.2939 12.5439C17.3979 12.5439 18.7899 12.8799 19.8939 13.4739V10.0659C18.6879 9.5859 17.4999 9.3999 16.2999 9.3999C13.3539 9.3999 11.3979 10.9359 11.3979 13.5039C11.3979 17.5059 16.9119 16.8699 16.9119 18.5979C16.9119 19.2639 16.3299 19.4799 15.5199 19.4799C14.3139 19.4799 12.7779 18.9879 11.5599 18.3219V21.7719C12.9099 22.3539 14.2719 22.5999 15.5199 22.5999C18.5379 22.5999 20.6139 21.1059 20.6139 18.5079C20.5899 14.1879 15.0639 14.9559 15.0639 13.3299Z"
			fill="white"
		/>
	</svg>
);
