import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AdobeSignFilterIcon } from './icon';
import { AdobeSignIcon } from './search-result-icon';
export const adobeSignConfig: ThirdPartyConfig = {
	displayName: 'Adobe Sign',
	id: 'adobe-sign',
	resourceType: 'adobe-sign-connector',
	icon: RovoLogoFromGlyph(AdobeSignFilterIcon, 'AdobeSign'),
	searchResultIcon: RovoLogoFromGlyph(AdobeSignIcon, 'AdobeSign'),
	integrationAri: 'ari:cloud:platform::integration/adobe',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => true,
};
