import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MuralFilterIcon } from './icon';
import { MuralIcon } from './search-result-icon';

export const muralConfig: ThirdPartyConfig = {
	displayName: 'Mural',
	id: 'mural',
	resourceType: 'smartlinks-connector-mural',
	icon: RovoLogoFromGlyph(MuralFilterIcon, 'Mural'),
	searchResultIcon: RovoLogoFromGlyph(MuralIcon, 'Mural'),
	integrationAri: 'ari:cloud:platform::integration/mural',
	typeFilterValues: [TypeFilterValueKey.Design],
	requiresOauth: true,
	isEnabled: () => true,
};
