import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { ZeplinFilterIcon } from './icon';
import { ZeplinIcon } from './search-result-icon';

export const zeplinConfig: ThirdPartyConfig = {
	displayName: 'Zeplin',
	id: 'zeplin',
	resourceType: 'smartlinks-connector-zeplin',
	icon: RovoLogoFromGlyph(ZeplinFilterIcon, 'Zeplin'),
	searchResultIcon: RovoLogoFromGlyph(ZeplinIcon, 'Zeplin'),
	integrationAri: 'ari:cloud:platform::integration/zeplin',
	typeFilterValues: [TypeFilterValueKey.Design],
	requiresOauth: true,
	isEnabled: () => false,
};
