import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GmailIcon } from './icon';

export const gmailConfig: ThirdPartyConfig = {
	displayName: 'Gmail',
	id: 'gmail',
	resourceType: 'gmail-connector',
	icon: RovoLogoFromGlyph(GmailIcon, 'Gmail'),
	searchResultIcon: RovoLogoFromGlyph(GmailIcon, 'Gmail'),
	integrationAri: 'ari:cloud:platform::integration/google',
	requiresOauth: true,
	typeFilterValues: [TypeFilterValueKey.Message],
	filters: ['lastUpdated'],
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_gmail'),
	isFederated: true,
};
