import React from 'react';

export const LucidFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.01072 13.6063C6.01072 11.5924 6.01608 9.57906 6.00429 7.5652C6.00268 7.28018 6.08739 7.12189 6.34258 6.97751C8.00454 6.03689 9.65525 5.07648 11.3097 4.12249C11.6394 3.93212 11.656 3.94067 11.656 4.31286C11.656 8.28657 11.656 12.2603 11.656 16.2345C11.656 16.3148 11.6614 16.3955 11.6555 16.4752C11.641 16.6773 11.7123 16.711 11.8844 16.6099C12.4002 16.3078 12.9197 16.0116 13.4376 15.7132C14.5972 15.0447 15.7568 14.3758 16.917 13.7079C17.192 13.5496 17.2231 13.5672 17.2231 13.8913C17.2241 15.7533 17.2241 17.6158 17.2231 19.4778C17.2231 19.8232 17.2027 19.843 16.8601 19.8436C15.2164 19.8462 13.5727 19.8425 11.9295 19.8543C11.663 19.8564 11.5697 19.7805 11.5751 19.5067C11.5912 18.7227 11.5815 17.9388 11.5804 17.1543C11.5804 16.8746 11.5751 16.8693 11.329 17.011C9.66543 17.9671 8.00347 18.9265 6.33936 19.8821C6.25144 19.9323 6.16191 20.0478 6.05469 19.9783C5.96676 19.9216 6.01126 19.796 6.01126 19.7013C6.00965 17.6698 6.00965 15.6383 6.00965 13.6068L6.01072 13.6063Z"
			fill="#222C35"
		/>
	</svg>
);
