import React from 'react';

export const ZeplinIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.39091 18.7117L7.23994 19.1179L7.17834 22.4248L11.1622 21.0194C9.79561 20.576 8.79791 19.7958 8.39091 18.7117Z"
			fill="#FDBD39"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.993 15.2139L5 16.6227L7.24033 19.1178L8.39093 18.7116C7.98503 17.6307 8.2351 16.4092 8.993 15.2139Z"
			fill="#F69833"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.7133 10.4418C10.628 12.236 7.34959 15.9385 8.39092 18.7116L26.8053 12.214C25.764 9.44091 20.7982 8.64758 15.7133 10.4418Z"
			fill="#FECF33"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.4833 20.4839C24.5683 18.6893 27.8525 15.0035 26.8057 12.2141L8.39056 18.7117C9.43776 21.5011 14.398 22.2781 19.4833 20.4839Z"
			fill="#EE6723"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M26.8057 12.2141L8.39056 18.7117C8.751 19.6722 10.1264 20.1453 12.0554 20.1453C13.9364 20.1453 16.3443 19.6959 18.8545 18.8101C23.9394 17.0159 27.517 14.1107 26.8049 12.2141"
			fill="#F69833"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.1404 10.7803C21.2594 10.7803 18.8515 11.2297 16.3413 12.1155C11.2564 13.9097 7.67884 16.8146 8.39091 18.7115L26.8053 12.2139C26.4448 11.2534 25.0698 10.7803 23.1404 10.7803Z"
			fill="#FDBD39"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.1234 22.6C21.5814 22.6 20.7825 22.1272 19.7474 21.195C18.5238 20.0921 17.1217 18.4768 15.7995 16.6461C14.4776 14.8161 13.3912 12.9861 12.7407 11.4937C12.0323 9.86735 11.9311 8.86753 12.4404 8.52151C12.5523 8.44572 12.6868 8.40747 12.8408 8.40747C13.2552 8.40747 14.0626 8.70533 15.5553 10.1252L15.5883 10.156L15.5835 10.2003C15.5732 10.3023 15.5641 10.4053 15.5556 10.5063L15.5391 10.7067L15.3925 10.5643C13.7839 8.99893 13.0759 8.82999 12.846 8.82999C12.7803 8.82999 12.7283 8.84274 12.6909 8.86824C12.4698 9.01805 12.4555 9.7526 13.1426 11.329C13.7817 12.7949 14.852 14.5969 16.1566 16.4035C17.4619 18.2104 18.8424 19.8021 20.0447 20.8851C21.3409 22.0532 21.925 22.1792 22.1164 22.1792C22.1824 22.1792 22.2356 22.1658 22.2755 22.1385C22.5084 21.9806 22.5087 21.1936 21.7424 19.4964L21.7072 19.4189L21.7842 19.3781C21.8612 19.3374 21.936 19.2974 22.0057 19.2591L22.0981 19.2085L22.1406 19.3023C22.6836 20.5047 23.1915 22.033 22.5256 22.4852C22.4134 22.5614 22.2781 22.6 22.1234 22.6Z"
			fill="#EE6723"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.6066 20.4332L19.5992 20.5253C19.4705 22.062 19.1317 23.909 18.2492 23.9841C18.2316 23.9855 18.2132 23.9862 18.1949 23.9862C17.595 23.9862 17.0278 23.1564 16.509 21.5201C16.0176 19.9717 15.6363 17.8935 15.4357 15.6668C15.2348 13.4412 15.2377 11.3297 15.4442 9.72213C15.6689 7.97006 16.0972 7.05525 16.7169 7.00248C16.7352 7.00106 16.7543 7 16.773 7C17.2078 7 17.8634 7.476 18.5344 9.69167L18.0963 9.77313C17.8722 9.03292 17.628 8.43083 17.3857 8.01646C17.1613 7.63396 16.9435 7.42323 16.7719 7.42323C16.766 7.42323 16.7605 7.42323 16.7546 7.42394C16.4862 7.4466 16.095 8.07596 15.8772 9.77419C15.6744 11.3531 15.6719 13.4327 15.8699 15.63C16.0686 17.8276 16.4433 19.8754 16.9259 21.3958C17.1418 22.0765 17.3787 22.6379 17.6112 23.0186C17.8253 23.3699 18.0325 23.5633 18.1945 23.5633C18.2 23.5633 18.2055 23.5633 18.2114 23.5626C18.4681 23.541 18.9524 22.8861 19.1563 20.5823L19.6066 20.4332Z"
			fill="#EE6723"
		/>
	</svg>
);
