import React from 'react';

export const NotionIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.44405 5.03539L19.7237 4.05453C21.355 3.91461 21.774 4.00893 22.7997 4.75459L27.0388 7.74085C27.7379 8.25444 27.9707 8.39436 27.9707 8.95355V25.3316C27.9707 26.358 27.598 26.9652 26.2931 27.0578L10.872 27.9919C9.89279 28.0382 9.42647 27.8983 8.9136 27.2448L5.79198 23.1855C5.23208 22.4384 5 21.8792 5 21.2255V6.66735C5 5.82809 5.37271 5.12826 6.44405 5.03539Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.7237 4.05477L6.44405 5.03563C5.37271 5.12826 5 5.82833 5 6.66735V21.2255C5 21.879 5.23208 22.4382 5.79198 23.1855L8.9136 27.2446C9.42647 27.8981 9.89279 28.0382 10.872 27.9917L26.2934 27.0581C27.5972 26.9652 27.9709 26.358 27.9709 25.3318V8.95379C27.9709 8.4234 27.7614 8.27052 27.1446 7.81789L22.7997 4.75459C21.7742 4.00893 21.355 3.91461 19.7237 4.05453V4.05477ZM11.2207 8.68572C9.96142 8.77043 9.67583 8.78963 8.96064 8.20813L7.14244 6.76191C6.95764 6.57472 7.05052 6.3412 7.51611 6.29464L20.2822 5.36178C21.3542 5.26818 21.9125 5.64185 22.3317 5.96825L24.5212 7.55462C24.6148 7.6019 24.8476 7.88101 24.5675 7.88101L11.3839 8.67468L11.2207 8.68572ZM9.75263 25.1919V11.2882C9.75263 10.681 9.93911 10.401 10.4973 10.3539L25.6396 9.46738C26.1532 9.42106 26.3853 9.74746 26.3853 10.3537V24.1647C26.3853 24.7719 26.2917 25.2855 25.4534 25.3318L10.9632 26.1718C10.1249 26.2181 9.75287 25.939 9.75287 25.1919H9.75263ZM24.0564 12.0337C24.1492 12.4536 24.0564 12.8736 23.6364 12.9216L22.938 13.0601V23.3254C22.3315 23.6518 21.7733 23.8383 21.3067 23.8383C20.5611 23.8383 20.3748 23.6048 19.8164 22.9054L15.2492 15.72V22.6719L16.694 22.999C16.694 22.999 16.694 23.839 15.5284 23.839L12.3148 24.0255C12.2212 23.8383 12.3148 23.372 12.6405 23.2791L13.4798 23.0463V13.8545L12.3151 13.7602C12.2215 13.3402 12.4543 12.7337 13.107 12.6867L16.5551 12.4546L21.307 19.7329V13.2939L20.0957 13.1547C20.0021 12.6404 20.3748 12.2667 20.8404 12.2211L24.0564 12.0337Z"
			fill="black"
		/>
	</svg>
);
