import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AhaFilterIcon } from './icon';
import { AhaIcon } from './search-result-icon';

export const ahaConfig: ThirdPartyConfig = {
	displayName: 'Aha',
	id: 'aha',
	resourceType: 'aha-connector',
	icon: RovoLogoFromGlyph(AhaFilterIcon, 'Aha'),
	searchResultIcon: RovoLogoFromGlyph(AhaIcon, 'Aha'),
	integrationAri: 'ari:cloud:platform::integration/aha',
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => true,
};
