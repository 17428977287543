import React from 'react';

export const TodoistIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24.1687 4H7.88539C5.76938 4 4.05402 5.71537 4.05402 7.83138V24.1147C4.05402 26.2307 5.76938 27.9461 7.88539 27.9461H24.1687C26.2848 27.9461 28.0001 26.2307 28.0001 24.1147V7.83138C28.0001 5.71537 26.2848 4 24.1687 4Z"
			fill="#DE483A"
		/>
		<path
			d="M9.11549 15.2196L13.8853 12.4748L13.8912 12.471L18.8062 9.64239C19.0135 9.52266 19.0232 9.15599 18.7912 9.02279L18.6288 8.93C18.3916 8.7953 18.0848 8.6202 17.9538 8.54387C17.8391 8.48139 17.7103 8.44941 17.5796 8.45098C17.449 8.45256 17.321 8.48763 17.2078 8.55285C17.0918 8.6202 9.34971 13.0712 9.09079 13.2186C8.93823 13.3058 8.76553 13.3517 8.5898 13.3517C8.41406 13.3517 8.24136 13.3058 8.0888 13.2186L4 10.8427V12.8624L4.04191 12.8863C5.06261 13.4813 7.47817 14.8881 8.06934 15.2211C8.42778 15.4231 8.77051 15.4186 9.11549 15.2196Z"
			fill="white"
		/>
		<path
			d="M9.11549 19.2545L13.8793 16.5127L13.8972 16.5022L18.8062 13.6773C19.0135 13.5576 19.0232 13.1902 18.7912 13.0577L18.6288 12.9649C18.3924 12.8302 18.0848 12.6551 17.9538 12.5788C17.8391 12.5163 17.7103 12.4843 17.5796 12.4859C17.449 12.4875 17.321 12.5225 17.2078 12.5878C17.0918 12.6544 9.34971 17.1061 9.09079 17.2528C8.93823 17.34 8.76553 17.3859 8.5898 17.3859C8.41406 17.3859 8.24136 17.34 8.0888 17.2528C7.84485 17.1121 4 14.8776 4 14.8776V16.8966L4.04191 16.9213C5.06336 17.5162 7.47817 18.9223 8.06934 19.256C8.42778 19.458 8.77051 19.4536 9.11549 19.2545Z"
			fill="white"
		/>
		<path
			d="M13.886 20.5439L9.11549 23.2894C8.77051 23.4885 8.42704 23.493 8.06934 23.2909C7.47817 22.9572 5.06336 21.5511 4.04191 20.9562L4 20.9315V18.9125L8.0888 21.2877C8.39785 21.4665 8.78099 21.4643 9.09079 21.2877C9.34971 21.141 17.0918 16.6893 17.2078 16.6219C17.321 16.5567 17.449 16.5216 17.5796 16.5201C17.7103 16.5185 17.8391 16.5505 17.9538 16.613C18.2324 16.774 18.5118 16.9337 18.792 17.0919C19.0239 17.2251 19.0135 17.5925 18.8062 17.7122L13.886 20.5439Z"
			fill="white"
		/>
	</svg>
);
