import React from 'react';

export const SmartsheetFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.2752 13.0096C12.0792 12.6216 11.8919 12.246 11.6708 11.8889C11.3903 11.436 11.086 11.0021 10.6703 10.6606C10.358 10.4042 10.0121 10.2417 9.59481 10.3033C9.23477 10.3565 8.9444 10.546 8.68299 10.7864C8.54499 10.9134 8.41894 11.0526 8.30327 11.2206C8.36586 11.229 8.41783 11.2339 8.46914 11.2432C8.8409 11.311 9.12685 11.5275 9.38449 11.7861C9.81751 12.2211 10.1397 12.7366 10.4283 13.2736C11.0754 14.4773 11.55 15.752 11.9561 17.0542C11.96 17.0668 11.9636 17.0795 11.9726 17.1094C13.6012 13.2139 15.4386 9.45323 17.9699 6.07414L17.9991 6.08389C17.9991 6.17142 17.9991 6.25896 17.9991 6.34627C17.9991 9.18597 18.0022 12.0257 17.9971 14.8656C17.9955 15.7212 17.9825 16.5773 17.919 17.4314C17.898 17.7144 17.869 17.9971 17.7686 18.2664C17.6514 18.5806 17.4272 18.6613 17.1425 18.4838C16.901 18.3331 16.7135 18.1224 16.5558 17.8901C16.2186 17.3935 15.8939 16.8887 15.6686 16.3275C15.6542 16.2916 15.6506 16.2099 15.5719 16.2863C15.0584 16.7856 14.4619 17.1717 13.8485 17.5307C12.0852 18.5623 10.2001 19.2905 8.22233 19.7855C7.51265 19.963 6.79766 20.1137 6.07759 20.2414C6.00793 20.2538 5.99001 20.2496 6.00483 20.17C6.10103 19.6504 6.14504 19.1258 6.14504 18.5966C6.14371 14.1099 6.14482 9.62321 6.1415 5.13651C6.1415 5.02415 6.1687 5 6.27862 5C10.1406 5.0031 14.0024 5.00266 17.8644 5.00266H17.9986C15.6294 7.34839 13.6289 9.93811 12.2758 13.0098L12.2752 13.0096Z"
			fill="#143158"
		/>
	</svg>
);
