import React from 'react';

export const DocusignIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.3513 21.281V27.4173C21.3513 27.7396 21.0901 28 20.7667 28H4.5846C4.2612 28 4 27.7396 4 27.4173V11.3016C4 10.9793 4.2612 10.719 4.5846 10.719H10.7291V20.6983C10.7291 21.0206 10.9903 21.281 11.3137 21.281H21.3513Z"
			fill="#4C00FF"
		/>
		<path
			d="M28.0927 12.6281C28.0927 17.7851 25.0703 21.2686 21.3512 21.281V14.7851C21.3512 14.5992 21.2766 14.4132 21.1398 14.2893L17.7566 10.9174C17.6198 10.781 17.4456 10.7066 17.2591 10.7066H10.7415V4.58268C10.7415 4.26036 11.0027 4.00003 11.3261 4.00003H20.4433C24.7717 3.98764 28.0927 7.45871 28.0927 12.6281Z"
			fill="#FF5252"
		/>
		<path
			d="M21.1398 14.2768C21.2766 14.4131 21.3512 14.5867 21.3512 14.7727V21.2685H11.3261C11.0027 21.2685 10.7415 21.0082 10.7415 20.6859V10.7065H17.2591C17.4456 10.7065 17.6322 10.7809 17.7566 10.9173L21.1398 14.2768Z"
			fill="black"
		/>
	</svg>
);
