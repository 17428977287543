import React from 'react';

export const AdobeXDFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.90598 4H17.5043C19.1111 4 20.4103 5.29915 20.4103 6.90598V17.094C20.4103 18.7009 19.1111 20 17.5043 20H6.90598C5.29915 20 4 18.7009 4 17.094V6.90598C4 5.29915 5.29915 4 6.90598 4Z"
			fill="#470137"
		/>
		<path
			d="M12.629 8.2051L10.5777 11.5897L12.7658 15.1795C12.7794 15.2068 12.7863 15.2342 12.7794 15.2615C12.7726 15.2889 12.7453 15.2684 12.7042 15.2752H11.1384C11.029 15.2752 10.9538 15.2684 10.9059 15.2C10.7624 14.9128 10.6119 14.6325 10.4683 14.3453C10.3247 14.0649 10.1675 13.7778 10.0034 13.4837C9.83928 13.1897 9.67518 12.8957 9.51107 12.5948H9.4974C9.35381 12.8889 9.19654 13.1829 9.03928 13.4769C8.88201 13.7709 8.72475 14.0649 8.57432 14.3521C8.41706 14.6393 8.25979 14.9333 8.10253 15.2136C8.07518 15.282 8.02047 15.2889 7.94526 15.2889H6.44099C6.41364 15.2889 6.39312 15.3025 6.39312 15.2684C6.38629 15.241 6.39312 15.2136 6.4068 15.1931L8.5333 11.706L6.4615 8.19827C6.44099 8.17092 6.43415 8.14356 6.44783 8.12989C6.4615 8.10938 6.48885 8.10254 6.5162 8.10254H8.06834C8.10253 8.10254 8.13671 8.10938 8.16406 8.11621C8.19142 8.12989 8.21193 8.1504 8.23244 8.17775C8.36236 8.47177 8.51278 8.76579 8.67005 9.0598C8.83415 9.35382 8.99142 9.641 9.16236 9.92818C9.32646 10.2154 9.47688 10.5025 9.62047 10.7966H9.63415C9.77774 10.4957 9.92817 10.2017 10.0786 9.9145C10.229 9.62733 10.3863 9.34015 10.5436 9.05297C10.7008 8.76579 10.8512 8.47177 11.0017 8.19143C11.0085 8.16408 11.0222 8.13673 11.0427 8.12305C11.07 8.10938 11.0974 8.10254 11.1316 8.10938H12.5743C12.6085 8.10254 12.6427 8.12305 12.6495 8.15724C12.6564 8.16408 12.6427 8.19143 12.629 8.2051Z"
			fill="#FF61F6"
		/>
		<path
			d="M15.788 15.4188C15.282 15.4256 14.776 15.323 14.3179 15.1111C13.8872 14.9128 13.5316 14.5846 13.2854 14.1812C13.0325 13.7641 12.9094 13.2444 12.9094 12.6222C12.9025 12.1162 13.0325 11.6171 13.2854 11.1794C13.5453 10.735 13.9213 10.3658 14.3726 10.1196C14.8513 9.85295 15.4256 9.72304 16.1025 9.72304C16.1367 9.72304 16.1846 9.72304 16.2461 9.72988C16.3077 9.73671 16.376 9.73671 16.4581 9.74355V7.58287C16.4581 7.53501 16.4786 7.50765 16.5265 7.50765H17.9145C17.9487 7.50082 17.976 7.52817 17.9829 7.55552C17.9829 7.56236 17.9829 7.56919 17.9829 7.56919V14.0786C17.9829 14.2017 17.9897 14.3384 17.9966 14.4889C18.0102 14.6324 18.0171 14.7692 18.0239 14.8854C18.0239 14.9333 18.0034 14.9743 17.9555 14.9948C17.6 15.1453 17.2239 15.2547 16.841 15.323C16.4923 15.3846 16.1436 15.4188 15.788 15.4188ZM16.4581 14.0512V11.0427C16.3966 11.029 16.335 11.0153 16.2735 11.0085C16.1983 11.0017 16.1231 10.9948 16.0478 10.9948C15.7812 10.9948 15.5145 11.0495 15.2752 11.1726C15.0427 11.2889 14.8444 11.4598 14.694 11.6786C14.5436 11.8974 14.4683 12.1914 14.4683 12.547C14.4615 12.7863 14.5025 13.0256 14.5846 13.2512C14.653 13.4359 14.7555 13.6 14.8923 13.7367C15.0222 13.8598 15.1795 13.9555 15.3572 14.0102C15.5419 14.0718 15.7333 14.0991 15.9248 14.0991C16.0273 14.0991 16.1231 14.0923 16.2119 14.0854C16.3008 14.0923 16.376 14.0786 16.4581 14.0512Z"
			fill="#FF61F6"
		/>
	</svg>
);
