import React from 'react';

export const OutlookMailIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M25.4456 5.5H12.5525C11.9748 5.5 11.4995 5.97531 11.4995 6.55295V7.74978L18.7665 9.99978L26.4985 7.74978V6.55295C26.4985 5.97531 26.0232 5.5 25.4456 5.5Z"
			fill="#0364B8"
		/>
		<path
			d="M27.7361 17.053C27.8459 16.7078 27.9336 16.3558 27.9985 15.9993C27.9986 15.821 27.9033 15.6557 27.7488 15.5666L27.7391 15.5613L27.7361 15.5599L19.608 10.9297C19.573 10.907 19.5367 10.8861 19.4994 10.8674C19.1846 10.7114 18.8144 10.7114 18.4997 10.8674C18.4623 10.8861 18.426 10.9069 18.3909 10.9297L10.263 15.5599L10.2599 15.5613L10.2501 15.5666C10.0957 15.6557 10.0004 15.821 10.0005 15.9993C10.0654 16.3558 10.1531 16.7078 10.263 17.053L18.8814 23.3563L27.7361 17.053Z"
			fill="#0A2767"
		/>
		<path
			d="M21.999 7.74976H16.7494L15.2336 9.99976L16.7494 12.2495L21.999 16.7492H26.4987V12.2495L21.999 7.74976Z"
			fill="#28A8EA"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4995 7.74976H16.7492V12.2494H11.4995V7.74976Z"
			fill="#0078D4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.999 7.74976H26.4987V12.2494H21.999V7.74976Z"
			fill="#50D9FF"
		/>
		<path
			d="M21.9989 16.7493L16.7492 12.2496H11.4995V16.7493L16.7492 21.2491L24.8726 22.575L21.9989 16.7493Z"
			fill="#0364B8"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.7493 12.2496H21.9989V16.7493H16.7493V12.2496Z"
			fill="#0078D4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4995 16.7493H16.7492V21.2489H11.4995V16.7493Z"
			fill="#064A8C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.999 16.7493H26.4987V21.2489H21.999V16.7493Z"
			fill="#0078D4"
		/>
		<path
			d="M19.1423 22.9124L10.2974 16.4629L10.6678 15.8112C10.6678 15.8112 18.7261 20.4008 18.849 20.4699C18.9509 20.5108 19.0654 20.5075 19.1648 20.4608C19.2795 20.3964 27.3632 15.7887 27.3632 15.7887L27.7352 16.4404L19.1423 22.9124Z"
			fill="#0A2767"
			fillOpacity="0.498039"
		/>
		<path
			d="M27.7488 16.4322L27.7391 16.4382L27.7368 16.4397L19.6089 21.0699C19.2809 21.2814 18.8655 21.3072 18.5139 21.1381L21.3449 24.9336L27.5351 26.2813V26.2844C27.8269 26.0733 28.0001 25.7341 28 25.3739V15.9995C28.0001 16.1778 27.9047 16.3431 27.7503 16.4322H27.7488Z"
			fill="#1490DF"
		/>
		<path
			d="M27.9985 25.3739V24.8205L20.5117 20.5547L19.608 21.0692C19.2802 21.2806 18.8648 21.3065 18.5132 21.1373L21.3442 24.9329L27.5343 26.2805V26.2836C27.8262 26.0724 27.9993 25.7333 27.9993 25.3731L27.9985 25.3739Z"
			fill="black"
			fillOpacity="0.047059"
		/>
		<path
			d="M27.9611 25.6612L19.7543 20.986L19.608 21.0692C19.2802 21.2806 18.8648 21.3066 18.5132 21.1374L21.3442 24.933L27.5343 26.2806V26.2836C27.7443 26.1313 27.8954 25.9111 27.9618 25.6604L27.9611 25.6612Z"
			fill="black"
			fillOpacity="0.098039"
		/>
		<path
			d="M10.262 16.442V16.4345H10.2546L10.232 16.4195C10.0865 16.3299 9.99811 16.1703 9.99953 15.9995V25.3754C9.99953 25.9917 10.5067 26.4989 11.1231 26.4989H26.8736C26.9672 26.4979 27.0605 26.4853 27.151 26.4613C27.198 26.4531 27.2435 26.438 27.2861 26.4164C27.302 26.4147 27.3173 26.4096 27.331 26.4013C27.3923 26.3762 27.4504 26.3435 27.5035 26.3038L27.5335 26.2813L10.262 16.442Z"
			fill="#28A8EA"
		/>
		<path
			d="M17.4991 22.4994V10.2504C17.4974 9.70259 17.0472 9.25237 16.4994 9.25073H11.5227V14.8423L10.262 15.5608L10.259 15.5623L10.2493 15.5675C10.0949 15.6568 9.99951 15.822 9.99951 16.0002V23.502V23.499H16.4994C17.0472 23.4975 17.4974 23.0471 17.4991 22.4994Z"
			fill="black"
			fillOpacity="0.098039"
		/>
		<path
			d="M16.7492 23.2493V11.0003C16.7475 10.4526 16.2972 10.0024 15.7495 10.0006H11.5227V14.8423L10.262 15.5608L10.259 15.5623L10.2493 15.5675C10.0949 15.6568 9.99951 15.822 9.99951 16.0002V24.252V24.249H15.7495C16.2972 24.2473 16.7475 23.7971 16.7492 23.2493ZM16.7492 21.7494V11.0003C16.7475 10.4526 16.2972 10.0024 15.7495 10.0006H11.5227V14.8423L10.262 15.5608L10.259 15.5623L10.2493 15.5675C10.0949 15.6568 9.99951 15.822 9.99951 16.0002V22.752V22.7491H15.7495C16.2972 22.7474 16.7475 22.2972 16.7492 21.7494ZM15.9992 21.7494V11.0003C15.9975 10.4526 15.5473 10.0024 14.9996 10.0006H11.5227V14.8423L10.262 15.5608L10.259 15.5623L10.2493 15.5675C10.0949 15.6568 9.99951 15.822 9.99951 16.0002V22.752V22.7491H14.9996C15.5473 22.7474 15.9975 22.2972 15.9992 21.7494Z"
			fill="black"
			fillOpacity="0.2"
		/>
		<path
			d="M4.99971 9.99988H14.9988C15.5472 9.99988 15.9985 10.4511 15.9985 10.9995V20.9986C15.9985 21.547 15.5472 21.9984 14.9988 21.9984H4.99971C4.45131 21.9984 4 21.547 4 20.9986V10.9995C4 10.4511 4.45131 9.99988 4.99971 9.99988Z"
			fill="#0078D4"
		/>
		<path
			d="M6.90014 14.1006C7.16577 13.5345 7.59472 13.0607 8.13155 12.7402C8.72643 12.3996 9.40377 12.23 10.089 12.2497C10.7236 12.2358 11.35 12.3967 11.8993 12.7147C12.4163 13.0225 12.8327 13.4742 13.0977 14.0144C13.3864 14.6096 13.5304 15.2646 13.5177 15.926C13.5318 16.6173 13.3835 17.3024 13.085 17.9262C12.8142 18.4855 12.3852 18.9534 11.8513 19.2716C11.2806 19.5996 10.6308 19.7649 9.97268 19.7493C9.32435 19.7647 8.6841 19.602 8.12185 19.279C7.60083 18.9706 7.17941 18.5185 6.90843 17.9771C6.61792 17.3904 6.47228 16.7425 6.48395 16.088C6.47108 15.4026 6.61366 14.7231 6.9009 14.1006H6.90014ZM8.2125 17.2939C8.35421 17.6518 8.59454 17.9624 8.90555 18.1893C9.22203 18.411 9.60145 18.5253 9.98763 18.5156C10.3991 18.5318 10.8048 18.4139 11.1433 18.1796C11.4505 17.9527 11.6845 17.6404 11.8161 17.2819C11.964 16.8822 12.0369 16.4586 12.0313 16.0324C12.0359 15.6026 11.9674 15.175 11.8289 14.7681C11.7067 14.4018 11.4811 14.0784 11.1793 13.8374C10.8498 13.5913 10.4452 13.4663 10.0342 13.4834C9.63963 13.4732 9.2517 13.5882 8.9265 13.8119C8.6097 14.0396 8.36403 14.3528 8.2185 14.7148C7.89668 15.5438 7.89483 16.4637 8.21326 17.2939H8.2125Z"
			fill="white"
		/>
	</svg>
);
