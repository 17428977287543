import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { SlackIcon } from './icon';
import { SlackSearchResultIcon } from './search-result-icon';

export const slackConfig: ThirdPartyConfig = {
	displayName: 'Slack',
	id: 'slack',
	resourceType: 'ai-3p-connector:slack-workspace',
	icon: RovoLogoFromGlyph(SlackIcon, 'Slack'),
	searchResultIcon: RovoLogoFromGlyph(SlackSearchResultIcon, 'Slack'),
	integrationAri: 'ari:cloud:platform::integration/slack',
	typeFilterValues: [TypeFilterValueKey.Conversation, TypeFilterValueKey.Message],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
