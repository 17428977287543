import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { PipedriveFilterIcon } from './icon';
import { PipedriveIcon } from './search-result-icon';

export const pipedriveConfig: ThirdPartyConfig = {
	displayName: 'Pipedrive',
	id: 'pipedrive',
	resourceType: 'smartlinks-connector-pipedrive',
	icon: RovoLogoFromGlyph(PipedriveFilterIcon, 'Pipedrive'),
	searchResultIcon: RovoLogoFromGlyph(PipedriveIcon, 'Pipedrive'),
	integrationAri: 'ari:cloud:platform::integration/pipedrive',
	typeFilterValues: [TypeFilterValueKey.Deal],
	requiresOauth: true,
	isEnabled: () => false,
};
