import React from 'react';

export const AzureDevopsIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M28 8.49486V23.0869L22 28L12.7 24.6143V27.9709L7.43543 21.0914L22.78 22.2914V9.15143L28 8.49486ZM22.8846 9.22857L14.2754 4V7.43029L6.36914 9.75314L4 12.7943V19.7011L7.39086 21.1977V12.3469L22.8846 9.22857Z"
			fill="url(#paint0_linear_24_918)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_24_918"
				x1="16"
				y1="27.9246"
				x2="16"
				y2="4.04457"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0078D4" />
				<stop offset="0.16" stopColor="#1380DA" />
				<stop offset="0.53" stopColor="#3C91E5" />
				<stop offset="0.82" stopColor="#559CEC" />
				<stop offset="1" stopColor="#5EA0EF" />
			</linearGradient>
		</defs>
	</svg>
);
