import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GithubIcon } from './icon';
import { GithubSearchResultIcon } from './search-result-icon';

export const githubConfig: ThirdPartyConfig = {
	displayName: 'GitHub',
	id: 'github',
	resourceType: 'github-connector',
	icon: RovoLogoFromGlyph(GithubIcon, 'Github'),
	searchResultIcon: RovoLogoFromGlyph(GithubSearchResultIcon, 'Github'),
	integrationAri: 'ari:cloud:platform::integration/github',
	typeFilterValues: [
		TypeFilterValueKey.Repository,
		TypeFilterValueKey.PullRequest,
		TypeFilterValueKey.Branch,
		TypeFilterValueKey.Commit,
	],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
