import React from 'react';

export const PagerDutyFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.9973 11.9999C18.9973 14.2951 18.9967 16.5905 18.9997 18.8857C18.9997 18.9754 18.9869 18.9997 18.8898 18.9995C14.2989 18.9962 9.70768 18.9962 5.11676 18.9995C5.01367 18.9997 5 18.9729 5 18.8789C5.00273 14.2929 5.00273 9.70686 5 5.12086C5 5.02707 5.01312 5 5.11676 5C9.70331 5.00355 14.2896 5.00355 18.8761 5C18.9825 5 19 5.02789 19 5.12742C18.997 7.41823 18.9975 9.70878 18.9975 11.9996L18.9973 11.9999Z"
			fill="#04AB38"
		/>
		<path
			d="M9.09461 10.1549C9.09461 9.25339 9.09515 8.35189 9.09351 7.45038C9.09351 7.36945 9.09242 7.31914 9.20343 7.31996C10.319 7.32652 11.4346 7.31832 12.5502 7.33171C13.1816 7.33937 13.784 7.48948 14.3278 7.8228C14.9939 8.23049 15.365 8.83778 15.4828 9.60147C15.6091 10.4204 15.4762 11.1885 14.9729 11.8633C14.5447 12.4378 13.948 12.7413 13.2543 12.8753C12.8229 12.9587 12.3862 12.9696 11.9498 12.9729C11.0393 12.98 10.1285 12.9737 9.21765 12.9786C9.10965 12.9792 9.09187 12.9425 9.09242 12.846C9.0957 11.9492 9.09433 11.052 9.09433 10.1552L9.09461 10.1549Z"
			fill="#FEFEFE"
		/>
		<path
			d="M9.10227 15.3932C9.10227 15.0202 9.10418 14.647 9.10063 14.274C9.09981 14.1928 9.10993 14.1548 9.20809 14.1564C9.59472 14.163 9.98163 14.1622 10.3683 14.1564C10.4579 14.1551 10.4708 14.1865 10.4705 14.2652C10.4678 15.0251 10.4672 15.785 10.4711 16.5446C10.4716 16.6329 10.4432 16.6485 10.3631 16.6477C9.97643 16.6438 9.58953 16.6427 9.20289 16.6482C9.11321 16.6496 9.09981 16.6184 9.10063 16.5394C9.10446 16.1571 9.10227 15.7751 9.10227 15.3929V15.3932Z"
			fill="#FEFEFE"
		/>
		<path
			d="M10.4648 10.1552C10.4648 9.64966 10.4659 9.14436 10.4634 8.63878C10.4632 8.56413 10.47 8.52558 10.5635 8.52613C11.1875 8.53078 11.8115 8.51847 12.4352 8.53296C12.7909 8.54117 13.1376 8.62155 13.4521 8.80175C13.8649 9.03827 14.0634 9.40877 14.1053 9.87169C14.1362 10.2148 14.1211 10.5536 13.9738 10.8735C13.7386 11.384 13.3011 11.6137 12.781 11.7253C12.5489 11.775 12.3127 11.7865 12.0756 11.7865C11.5837 11.7863 11.0918 11.7819 10.6002 11.7898C10.4804 11.7917 10.461 11.7526 10.4623 11.6446C10.4681 11.1483 10.4648 10.6518 10.4648 10.1555V10.1552Z"
			fill="#04AB38"
		/>
	</svg>
);
