import React from 'react';

export const ClickUpIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.62964 21.3703L10.0837 18.4814C11.9304 21.074 13.8797 22.2963 16.0342 22.2963C18.1887 22.2963 20.1038 21.1111 21.8479 18.5185L25.3704 21.3333C22.8397 25.037 19.6934 27 16.0342 27C12.4092 27 9.22872 25.037 6.62964 21.3703Z"
			fill="url(#paint0_linear_384_99)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 9.77629L9.58683 15.0741L6.62964 11.7587L16.0356 4L25.3704 11.7587L22.3776 15.0399L16 9.77629Z"
			fill="url(#paint1_linear_384_99)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_384_99"
				x1="6.62964"
				y1="22.7449"
				x2="25.3856"
				y2="22.7449"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#8930FD" />
				<stop offset="1" stopColor="#49CCF9" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_384_99"
				x1="6.62796"
				y1="9.53512"
				x2="25.3507"
				y2="9.53512"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF02F0" />
				<stop offset="1" stopColor="#FFC800" />
			</linearGradient>
		</defs>
	</svg>
);
