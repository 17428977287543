import React from 'react';

export const DocusignFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.0826 15.0417V18.6075C15.0826 18.7948 14.9308 18.946 14.7429 18.946H5.3397C5.15178 18.946 5 18.7948 5 18.6075V9.24286C5 9.05557 5.15178 8.9043 5.3397 8.9043H8.91017V14.7031C8.91017 14.8904 9.06195 15.0417 9.24987 15.0417H15.0826Z"
			fill="#4C00FF"
		/>
		<path
			d="M19 10.0137C19 13.0104 17.2437 15.0346 15.0826 15.0418V11.2671C15.0826 11.159 15.0393 11.051 14.9598 10.979L12.9938 9.0196C12.9143 8.94036 12.8131 8.89714 12.7047 8.89714H8.91742V5.33859C8.91742 5.15129 9.0692 5.00002 9.25712 5.00002H14.555C17.0702 4.99282 19 7.00981 19 10.0137Z"
			fill="#FF5252"
		/>
		<path
			d="M14.9598 10.9717C15.0393 11.051 15.0826 11.1518 15.0826 11.2599V15.0345H9.25712C9.0692 15.0345 8.91742 14.8832 8.91742 14.6959V8.89709H12.7047C12.8131 8.89709 12.9215 8.94032 12.9938 9.01955L14.9598 10.9717Z"
			fill="black"
		/>
	</svg>
);
