import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { TodoistFilterIcon } from './icon';
import { TodoistIcon } from './search-result-icon';

export const todoistConfig: ThirdPartyConfig = {
	displayName: 'Todoist',
	id: 'todoist',
	resourceType: 'smartlinks-connector-todoist',
	icon: RovoLogoFromGlyph(TodoistFilterIcon, 'Todoist'),
	searchResultIcon: RovoLogoFromGlyph(TodoistIcon, 'Todoist'),
	integrationAri: 'ari:cloud:platform::integration/todoist',
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => true,
};
