import React from 'react';

export const OutlookCalendarIcon = () => (
	<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M25.4456 5.5H12.5525C11.9748 5.5 11.4995 5.97531 11.4995 6.55295V7.74978L18.7665 9.99978L26.4985 7.74978V6.55295C26.4985 5.97531 26.0232 5.5 25.4456 5.5Z"
			fill="#0364B8"
		/>
		<path
			d="M27.7358 17.053C27.8457 16.7078 27.9334 16.3558 27.9983 15.9993C27.9984 15.821 27.903 15.6557 27.7486 15.5666L27.7389 15.5613L27.7358 15.5599L19.6078 10.9297C19.5728 10.907 19.5364 10.8861 19.4991 10.8674C19.1844 10.7114 18.8141 10.7114 18.4994 10.8674C18.4621 10.8861 18.4258 10.9069 18.3906 10.9297L10.2627 15.5599L10.2597 15.5613L10.2498 15.5666C10.0955 15.6557 10.0001 15.821 10.0002 15.9993C10.0652 16.3558 10.1529 16.7078 10.2627 17.053L18.8811 23.3563L27.7358 17.053Z"
			fill="#0A2767"
		/>
		<path
			d="M21.9988 7.74982H16.7491L15.2334 9.99982L16.7491 12.2496L21.9988 16.7493H26.4985V12.2496L21.9988 7.74982Z"
			fill="#28A8EA"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4996 7.74982H16.7493V12.2495H11.4996V7.74982Z"
			fill="#0078D4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9989 7.74982H26.4986V12.2495H21.9989V7.74982Z"
			fill="#50D9FF"
		/>
		<path
			d="M21.999 16.7492L16.7493 12.2496H11.4996V16.7492L16.7493 21.249L24.8728 22.5749L21.999 16.7492Z"
			fill="#0364B8"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.7493 12.2496H21.9989V16.7492H16.7493V12.2496Z"
			fill="#0078D4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4996 16.7493H16.7493V21.2489H11.4996V16.7493Z"
			fill="#064A8C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9989 16.7493H26.4986V21.2489H21.9989V16.7493Z"
			fill="#0078D4"
		/>
		<path
			d="M19.1423 22.9124L10.2974 16.4628L10.6678 15.8111C10.6678 15.8111 18.7261 20.4008 18.849 20.4698C18.9509 20.5107 19.0654 20.5075 19.1648 20.4608C19.2795 20.3963 27.3632 15.7886 27.3632 15.7886L27.7352 16.4403L19.1423 22.9124Z"
			fill="#0A2767"
			fillOpacity="0.498039"
		/>
		<path
			d="M27.7488 16.4321L27.7391 16.4381L27.7368 16.4396L19.6089 21.0699C19.2809 21.2813 18.8655 21.3072 18.5139 21.1381L21.3449 24.9336L27.5351 26.2813V26.2843C27.8269 26.0732 28.0001 25.7341 28 25.3739V15.9995C28.0001 16.1777 27.9047 16.3431 27.7503 16.4321H27.7488Z"
			fill="#1490DF"
		/>
		<path
			d="M27.9985 25.3738V24.8204L20.5117 20.5546L19.608 21.0691C19.2802 21.2805 18.8648 21.3065 18.5132 21.1373L21.3442 24.9329L27.5343 26.2805V26.2835C27.8262 26.0723 27.9993 25.7333 27.9993 25.3731L27.9985 25.3738Z"
			fill="black"
			fillOpacity="0.047059"
		/>
		<path
			d="M27.9611 25.661L19.7543 20.9858L19.608 21.0691C19.2802 21.2805 18.8648 21.3065 18.5132 21.1373L21.3442 24.9329L27.5343 26.2805V26.2835C27.7443 26.1312 27.8954 25.911 27.9618 25.6603L27.9611 25.661Z"
			fill="black"
			fillOpacity="0.098039"
		/>
		<path
			d="M10.2621 16.4419V16.4344H10.2547L10.2321 16.4195C10.0866 16.3299 9.99823 16.1703 9.99965 15.9995V25.3754C9.99965 25.9916 10.5068 26.4988 11.1232 26.4988H26.8737C26.9673 26.4978 27.0606 26.4853 27.1511 26.4613C27.1982 26.4531 27.2437 26.4379 27.2862 26.4163C27.3021 26.4147 27.3174 26.4096 27.3311 26.4013C27.3925 26.3762 27.4505 26.3435 27.5036 26.3037L27.5336 26.2813L10.2621 16.4419Z"
			fill="#28A8EA"
		/>
		<path
			d="M17.4992 22.4993V10.2503C17.4976 9.70247 17.0473 9.25225 16.4995 9.25061H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V23.5019V23.4989H16.4995C17.0473 23.4973 17.4976 23.047 17.4992 22.4993Z"
			fill="black"
			fillOpacity="0.098039"
		/>
		<path
			d="M16.7493 23.2491V11.0002C16.7477 10.4525 16.2973 10.0022 15.7496 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V24.2519V24.2489H15.7496C16.2973 24.2472 16.7477 23.797 16.7493 23.2491ZM16.7493 21.7493V11.0002C16.7477 10.4525 16.2973 10.0022 15.7496 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V22.7519V22.749H15.7496C16.2973 22.7473 16.7477 22.2971 16.7493 21.7493ZM15.9993 21.7493V11.0002C15.9977 10.4525 15.5475 10.0022 14.9997 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V22.7519V22.749H14.9997C15.5475 22.7473 15.9977 22.2971 15.9993 21.7493Z"
			fill="black"
			fillOpacity="0.2"
		/>
		<path
			d="M4.99971 9.99988H14.9988C15.5472 9.99988 15.9985 10.4511 15.9985 10.9995V20.9986C15.9985 21.547 15.5472 21.9984 14.9988 21.9984H4.99971C4.45131 21.9984 4 21.547 4 20.9986V10.9995C4 10.4511 4.45131 9.99988 4.99971 9.99988Z"
			fill="#0078D4"
		/>
		<path
			d="M6.90014 14.1005C7.16577 13.5344 7.59472 13.0606 8.13155 12.7401C8.72643 12.3995 9.40377 12.2299 10.089 12.2497C10.7236 12.2357 11.35 12.3966 11.8993 12.7146C12.4163 13.0225 12.8327 13.4741 13.0977 14.0143C13.3864 14.6095 13.5304 15.2645 13.5177 15.9259C13.5318 16.6172 13.3835 17.3023 13.085 17.9261C12.8142 18.4854 12.3852 18.9533 11.8513 19.2715C11.2806 19.5995 10.6308 19.7648 9.97268 19.7492C9.32435 19.7646 8.6841 19.6019 8.12185 19.2789C7.60083 18.9705 7.17941 18.5185 6.90843 17.977C6.61792 17.3903 6.47228 16.7425 6.48395 16.0879C6.47108 15.4025 6.61366 14.7231 6.9009 14.1005H6.90014ZM8.2125 17.2938C8.35421 17.6517 8.59454 17.9623 8.90555 18.1892C9.22203 18.4109 9.60145 18.5252 9.98763 18.5155C10.3991 18.5318 10.8048 18.4138 11.1433 18.1795C11.4505 17.9526 11.6845 17.6404 11.8161 17.2818C11.964 16.8821 12.0369 16.4585 12.0313 16.0324C12.0359 15.6026 11.9674 15.1749 11.8289 14.768C11.7067 14.4017 11.4811 14.0783 11.1793 13.8374C10.8498 13.5913 10.4452 13.4662 10.0342 13.4834C9.63963 13.4731 9.2517 13.5881 8.9265 13.8118C8.6097 14.0395 8.36403 14.3527 8.2185 14.7148C7.89668 15.5438 7.89483 16.4636 8.21326 17.2938H8.2125Z"
			fill="white"
		/>
	</svg>
);
