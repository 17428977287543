import React from 'react';

export const DovetailIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26.08 4H5.92C4.85961 4 4 4.85961 4 5.92V26.08C4 27.1404 4.85961 28 5.92 28H26.08C27.1404 28 28 27.1404 28 26.08V5.92C28 4.85961 27.1404 4 26.08 4Z"
			fill="#0C0020"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.77459 17.8483C9.74132 17.8675 9.74132 17.9155 9.77459 17.9347L12.9086 19.7447C12.9395 19.7625 12.9585 19.7954 12.9585 19.8311V23.45C12.9585 23.4884 13.0001 23.5124 13.0334 23.4932L16.1674 21.6833C16.1982 21.6654 16.2173 21.6325 16.2173 21.5968V17.9491C16.2173 17.9135 16.1982 17.8805 16.1673 17.8627L12.9585 16.0105L9.77459 17.8483Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.77459 10.3223C9.74132 10.3416 9.74132 10.3896 9.77459 10.4088L12.9086 12.2187C12.9395 12.2366 12.9585 12.2695 12.9585 12.3052V16.0105L16.2173 14.1285V10.4232C16.2173 10.3875 16.1982 10.3546 16.1673 10.3367L13.0084 8.51339C12.9775 8.49554 12.9395 8.49554 12.9086 8.51339L9.77459 10.3223Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.2172 14.1285L19.4261 15.9817C19.457 15.9995 19.476 16.0325 19.476 16.0681V19.687C19.476 19.7254 19.5176 19.7494 19.5509 19.7302L22.6849 17.9203C22.7158 17.9024 22.7348 17.8695 22.7348 17.8338V14.1861C22.7348 14.1505 22.7158 14.1175 22.6849 14.0997L19.5259 12.2763C19.495 12.2585 19.457 12.2585 19.4261 12.2763L16.2172 14.1285Z"
			fill="white"
		/>
	</svg>
);
