import React from 'react';

export const ZendeskFilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.4666 9.80166V17.609H5L11.4666 9.80166ZM11.4666 7C11.4666 7.85753 11.126 8.67994 10.5196 9.2863C9.91326 9.89267 9.09085 10.2333 8.23332 10.2333C7.37579 10.2333 6.55338 9.89267 5.94702 9.2863C5.34065 8.67994 5 7.85753 5 7H11.4666ZM12.532 17.6096C12.532 16.7521 12.8726 15.9297 13.479 15.3233C14.0854 14.717 14.9078 14.3763 15.7653 14.3763C16.6228 14.3763 17.4452 14.717 18.0516 15.3233C18.658 15.9297 18.9986 16.7521 18.9986 17.6096H12.532ZM12.532 14.808V7H19L12.532 14.8073V14.808Z"
			fill="#03363D"
		/>
	</svg>
);
